import { useFetch } from '../../../hooks/useFetch';
import { DailyWork } from '../../../models/daily-work';
import { errorNotification } from '../../../providers/mantine-notifications';
import { translateServerHttpErrors } from '../../../utils/helpers';
import {
  UseAddDailyWorkJustificationProps,
  UseAddDailyWorkManuallyProps,
  UseEditAppointmentsProps,
  UseEditDailyWorkJustificationProps,
  UseEditDailyWorkProps,
  UseFaultToJustificationProps,
  UseGenerateUserMirrorProps,
  UseGetLateWorksProps,
} from '../../../utils/types/data/hooks/daily-work';
import { PaginatedResponse } from '../../../utils/types/data/pagination';
import { GetLateWorkResponse } from '../../../utils/types/data/services/daily-work';
import {
  addDailyWorkJustificationRequest,
  addDailyWorkManuallyRequest,
  editAppointmentsRequest,
  editDailyWorkJustificationRequest,
  editDailyWorkRequest,
  faultToJustificationRequest,
  generateUserMirrorRequest,
  getFaultWorkRequest,
  getLateWorkRequest,
  getOverIntervalWorkRequest,
} from '../../daily-work';

export function useAddDailyWorkManually() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ data, onSuccess }: UseAddDailyWorkManuallyProps) =>
    fetchData({
      fetcher: () => addDailyWorkManuallyRequest(data),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao adicionar horário.',
          message: translateServerHttpErrors(error, 'Tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useEditDailyWork() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<DailyWork>();

  const fetch = ({ id, data, onSuccess }: UseEditDailyWorkProps) =>
    fetchData({
      fetcher: () => editDailyWorkRequest(id, data),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao editar horário.',
          message: translateServerHttpErrors(error, 'Tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useAddDailyWorkJustification() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ data, onSuccess }: UseAddDailyWorkJustificationProps) =>
    fetchData({
      fetcher: () => addDailyWorkJustificationRequest(data),
      onError: (error: any) => {
        errorNotification({
          title: error.response?.data.message,
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGenerateUserMirror() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<Blob>();

  const fetch = ({ params, onSuccess }: UseGenerateUserMirrorProps) =>
    fetchData({
      fetcher: () => generateUserMirrorRequest(params),
      onError: async (error: any) => {
        const convertedError = JSON.parse(await error.response.data.text());
        errorNotification({
          title: 'Erro ao gerar espelho de ponto.',
          message: translateServerHttpErrors(
            { response: { data: convertedError } as any } as any,
            'Tente novamente',
          ),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useEditDailyWorkJustification() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseEditDailyWorkJustificationProps) =>
    fetchData({
      fetcher: () => editDailyWorkJustificationRequest(id, data),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao editar justificativa',
          message: translateServerHttpErrors(error, 'Tente novamente'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetLateWorks() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaginatedResponse<GetLateWorkResponse>>();

  const fetch = ({ query, onSuccess }: UseGetLateWorksProps) =>
    fetchData({
      fetcher: () => getLateWorkRequest(query),
      onError: async (error: any) => {
        errorNotification({
          title: 'Erro buscar pontos em atraso.',
          message: translateServerHttpErrors(error, 'Tente novamente'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetFaultWorks() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaginatedResponse<GetLateWorkResponse>>();

  const fetch = ({ query, onSuccess }: UseGetLateWorksProps) =>
    fetchData({
      fetcher: () => getFaultWorkRequest(query),
      onError: async (error: any) => {
        errorNotification({
          title: 'Erro buscar faltas.',
          message: translateServerHttpErrors(error, 'Tente novamente'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useEditAppointments() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseEditAppointmentsProps) =>
    fetchData({
      fetcher: () => editAppointmentsRequest(id, data),
      onError: async (error: any) => {
        errorNotification({
          title: 'Erro editar apontamento.',
          message: translateServerHttpErrors(error, 'Tente novamente'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useFaultToJustification() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseFaultToJustificationProps) =>
    fetchData({
      fetcher: () => faultToJustificationRequest(id, data),
      onError: async (error: any) => {
        errorNotification({
          title: 'Erro editar falta.',
          message: translateServerHttpErrors(error, 'Tente novamente'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
export function useGetOverIntervalWorks() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaginatedResponse<GetLateWorkResponse>>();

  const fetch = ({ query, onSuccess }: UseGetLateWorksProps) =>
    fetchData({
      fetcher: () => getOverIntervalWorkRequest(query),
      onError: async (error: any) => {
        errorNotification({
          title: 'Erro buscar intervalos.',
          message: translateServerHttpErrors(error, 'Tente novamente'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

import {
  Button,
  Container,
  Flex,
  Grid,
  Group,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft, IconClock } from '@tabler/icons';
import { useState } from 'react';

import { TimeInput } from '@mantine/dates';
import { CustomLoader } from '../../components/custom-loader';
import { rootGoBack, rootNavigate } from '../../components/custom-router';
import { Page } from '../../components/page';
import { createWorkScaleRequest } from '../../data/work-scale';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { AppRoutePaths } from '../../utils/enums/routes';
import { dayjsPlugins } from '../../providers/dayjs-plugins';

type Days = {
  0: string[];
  1: string[];
  2: string[];
  3: string[];
  4: string[];
  5: string[];
  6: string[];
};

type CreateWorkScaleForm = {
  name: string;
  days: Days;
};

export function WorkScaleCreate() {
  const [pageLoading, setPageLoading] = useState(false);

  const form = useForm<CreateWorkScaleForm>({
    initialValues: {
      name: '',
      days: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
      },
    },
  });

  async function handleSubmit({ ...values }: typeof form.values) {
    try {
      setPageLoading(true);
      await createWorkScaleRequest(values);
      setPageLoading(false);
      successNotification({
        title: 'Escala cadastrada.',
        message: 'tudo certo.',
      });
      form.reset();
      rootNavigate(AppRoutePaths.WORK_SCALE_LIST);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao cadastrar escala.',
        message: 'tente novamente.',
      });
    }
  }

  return (
    <Page>
      <CustomLoader loading={pageLoading} />
      <Container bg="white" h="100%">
        <Button
          leftIcon={<IconArrowLeft />}
          onClick={rootGoBack}
          mt={16}
          mb={16}
          variant="subtle"
        >
          voltar
        </Button>
        <Title>Cadastro de escala</Title>
        <Text color="gray" mb={16}>
          dados da nova escala
        </Text>
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Grid gutter="xs" columns={2} m={8} w={500} maw={500}>
            <Grid.Col span={2} mb={'sm'}>
              <TextInput
                required
                withAsterisk
                label="Nome"
                placeholder="nome da escala"
                type="text"
                {...form.getInputProps('name')}
              />
            </Grid.Col>

            <Grid.Col span={1}>
              <Flex direction={'column'} gap={'xl'} justify={'center'} mt={5}>
                <Text fw={'bold'} size={'md'}>
                  Domingo
                </Text>
                <Text fw={'bold'} size={'md'}>
                  Segunda-Feira
                </Text>
                <Text fw={'bold'} size={'md'}>
                  Terça-Feira
                </Text>
                <Text fw={'bold'} size={'md'}>
                  Quarta-Feira
                </Text>
                <Text fw={'bold'} size={'md'}>
                  Quinta-Feira
                </Text>
                <Text fw={'bold'} size={'md'}>
                  Sexta-Feira
                </Text>
                <Text fw={'bold'} size={'md'}>
                  Sábado
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction={'column'} gap={'sm'}>
                <Flex gap={'sm'}>
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.0.0',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['0'].shift();
                    }}
                  />
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.0.1',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['0'].pop();
                    }}
                  />
                </Flex>
                <Flex gap={'sm'}>
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.1.0',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['1'].shift();
                    }}
                  />
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.1.1',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['1'].pop();
                    }}
                  />
                </Flex>
                <Flex gap={'sm'}>
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.2.0',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['2'].shift();
                    }}
                  />
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.2.1',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['2'].pop();
                    }}
                  />
                </Flex>
                <Flex gap={'sm'}>
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.3.0',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['3'].shift();
                    }}
                  />
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.3.1',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['3'].pop();
                    }}
                  />
                </Flex>
                <Flex gap={'sm'}>
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.4.0',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['4'].shift();
                    }}
                  />
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.4.1',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['4'].pop();
                    }}
                  />
                </Flex>
                <Flex gap={'sm'}>
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.5.0',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['5'].shift();
                    }}
                  />
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.5.1',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['5'].pop();
                    }}
                  />
                </Flex>
                <Flex gap={'sm'}>
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.6.0',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['6'].shift();
                    }}
                  />
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.6.1',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['6'].pop();
                    }}
                  />
                </Flex>
              </Flex>
            </Grid.Col>
          </Grid>
          <Group mt={16} mb={16} position="right">
            <Button type="submit">Salvar</Button>
          </Group>
        </form>
      </Container>
    </Page>
  );
}

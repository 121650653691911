import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import CustomRouter from './components/custom-router';
import AuthProvider from './context/AuthContext';
import CashFlowProvider from './context/CashFlowContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <CustomRouter>
      <AuthProvider>
        <CashFlowProvider>
          <App />
        </CashFlowProvider>
      </AuthProvider>
    </CustomRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import {
  Button,
  Flex,
  Menu,
  Modal,
  Select,
  Tabs,
  Text,
  Grid,
  Group,
  TextInput,
  Switch,
  Badge,
  Tooltip,
  Textarea,
} from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import {
  IconAlertTriangle,
  IconClock,
  IconDotsVertical,
  IconEdit,
  IconFile,
  IconHelp,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CustomDateRangePicker } from '../../components/custom-date-range-picker';
import { CustomDateTimePicker } from '../../components/custom-date-time-picker';
import { CustomLoader } from '../../components/custom-loader';
import { Page } from '../../components/page';
import {
  useEditAppointments,
  useFaultToJustification,
  useGetFaultWorks,
  useGetLateWorks,
  useGetOverIntervalWorks,
} from '../../data/hooks/daily-work';
import { useGetFilterUsers } from '../../data/hooks/filters';
import { useGetWorkScaleList } from '../../data/hooks/work-scale';
import { dayjsPlugins, formatLocale } from '../../providers/dayjs-plugins';
import { errorNotification } from '../../providers/mantine-notifications';
import { faultJustification } from '../../utils/constants/daily-work';
import { secondsToTime, timeToSeconds } from '../../utils/helpers';
import { GetLateWorkResponse } from '../../utils/types/data/services/daily-work';

export function WorkAlerts() {
  const {
    fetch: getLateWorksFetcher,
    response: getLateWorksData,
    loading: getLateWorksLoader,
  } = useGetLateWorks();

  const {
    fetch: getFaultWorksFetcher,
    response: getFaultWorksData,
    loading: getFaultWorksLoader,
  } = useGetFaultWorks();

  const {
    fetch: getUserFiltersFetcher,
    response: getUserFiltersData,
    loading: getUserFiltersLoader,
  } = useGetFilterUsers();

  const {
    fetch: getWorkScaleFetcher,
    response: getWorkScaleData,
    loading: getWorkScaleLoading,
  } = useGetWorkScaleList();

  const { fetch: editAppointmentsFetcher, loading: editAppointmentsLoader } =
    useEditAppointments();

  const {
    fetch: faultToJustificationFetcher,
    loading: faultToJustificationLoader,
  } = useFaultToJustification();

  const {
    fetch: getOverIntervalFetcher,
    response: getOverIntervalData,
    loading: getOverIntervalLoading,
  } = useGetOverIntervalWorks();

  const currentPage = useRef(1);
  const currentPageLimit = useRef(10);
  const [activeTab, setActiveTab] = useState<
    'over-interval' | 'late' | 'fault' | null
  >('late');
  const [pageModalVisible, setPageModalVisible] = useState<
    'edit-appointment' | 'fault-justification' | null
  >(null);
  const [selectedAppointment, setSelectedAppointment] =
    useState<GetLateWorkResponse | null>(null);

  const filterForm = useForm({
    initialValues: {
      scale: '',
      date: undefined,
      user: '',
      page: 0,
      limit: 0,
    },
  });

  const editAppointmentForm = useForm({
    initialValues: {
      accumulatorDate: new Date(),
      accumulator: '',
      accumulatorPositive: true,
      workTimeDate: new Date(),
      workTime: '',
      description: '',
    },
  });

  const faultJustificationForm = useForm({
    initialValues: {
      description: '',
      justification: '',
      begin: new Date(),
      finish: new Date(),
    },
  });

  const faultJustificationTime = useMemo(() => {
    return secondsToTime(
      dayjsPlugins(faultJustificationForm.values.finish).diff(
        faultJustificationForm.values.begin,
        'seconds',
      ),
    );
  }, [
    faultJustificationForm.values.begin,
    faultJustificationForm.values.finish,
  ]);

  function handleFilter() {
    if (!filterForm.values.date || !filterForm.values.scale) {
      errorNotification({
        title: 'Campos obrigatórios!',
        message: 'selecione uma escala e uma data',
      });
      return;
    }

    if (activeTab === 'late') {
      getLateWorks();
    } else if (activeTab == 'fault') {
      getFaultWorks();
    } else if (activeTab == 'over-interval') {
      getOverIntervalWorks();
    }
  }

  function handleClear() {
    filterForm.reset();
    currentPage.current = 1;
    currentPageLimit.current = 10;

    if (activeTab === 'late') {
      getLateWorks();
    } else if (activeTab == 'fault') {
      getFaultWorks();
    }
  }

  function handlePaginate(page: number) {
    currentPage.current = page;

    if (activeTab === 'late') {
      getLateWorks();
    } else if (activeTab == 'fault') {
      getFaultWorks();
    }
  }

  function handleChangeLimit(limit: number) {
    currentPageLimit.current = limit;

    if (activeTab === 'late') {
      getLateWorks();
    } else if (activeTab == 'fault') {
      getFaultWorks();
    }
  }

  function selectAppointment(item: GetLateWorkResponse) {
    const convertedWorkTime = secondsToTime(item.workTime);
    const convertedAccumulator = secondsToTime(item.accumulatorTime);

    setSelectedAppointment(item);
    editAppointmentForm.setFieldValue(
      'workTimeDate',
      dayjsPlugins(
        `${dayjsPlugins().format(
          'YYYY-MM-DD',
        )}T${convertedWorkTime.toString()}`,
      ).toDate(),
    );
    editAppointmentForm.setFieldValue('workTime', convertedWorkTime.toString());
    editAppointmentForm.setFieldValue(
      'accumulatorDate',
      dayjsPlugins(
        `${dayjsPlugins().format(
          'YYYY-MM-DD',
        )}T${convertedAccumulator.toString()}`,
      ).toDate(),
    );
    editAppointmentForm.setFieldValue(
      'accumulator',
      convertedAccumulator.toString(),
    );
    editAppointmentForm.setFieldValue('description', item.description);
    setPageModalVisible('edit-appointment');
  }

  function setFaultToJustification(item: GetLateWorkResponse) {
    setSelectedAppointment(item);
    const baseDate = dayjsPlugins(item.date).startOf('day').toDate();
    faultJustificationForm.setFieldValue('begin', baseDate);
    faultJustificationForm.setFieldValue('finish', baseDate);
    setPageModalVisible('fault-justification');
  }

  async function getLateWorks() {
    const validatedValues: any = {};

    Object.entries(filterForm.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedValues[key] = val;
      }
    });

    await getLateWorksFetcher({
      query: {
        ...validatedValues,
        page: currentPage.current,
        limit: currentPageLimit.current,
        scale: Number(filterForm.values.scale),
        date: validatedValues?.date
          ? validatedValues.date.join(',')
          : undefined,
      },
    });
  }

  async function getFaultWorks() {
    const validatedValues: any = {};

    Object.entries(filterForm.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedValues[key] = val;
      }
    });

    await getFaultWorksFetcher({
      query: {
        ...validatedValues,
        page: currentPage.current,
        limit: currentPageLimit.current,
        scale: Number(filterForm.values.scale),
        date: validatedValues?.date
          ? validatedValues.date.join(',')
          : undefined,
      },
    });
  }

  async function getWorkScale() {
    await getWorkScaleFetcher({});
  }

  async function getUsersFilter() {
    await getUserFiltersFetcher({});
  }

  async function handleEditAppointment(
    id: number,
    { accumulatorPositive, ...values }: typeof editAppointmentForm.values,
  ) {
    const validatedValues: any = {};

    Object.entries(values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedValues[key] = val;
      }
    });

    const totalAccumulator = timeToSeconds(validatedValues.accumulator ?? '0');
    const totalWorkTime = timeToSeconds(validatedValues.workTime ?? '0');

    await editAppointmentsFetcher({
      id,
      data: {
        ...validatedValues,
        accumulator: accumulatorPositive ? totalAccumulator : -totalAccumulator,
        workTime: totalWorkTime,
      },
      onSuccess: () => {
        editAppointmentForm.reset();
        setPageModalVisible(null);
        setSelectedAppointment(null);
        getLateWorks();
        getFaultWorks();
      },
    });
  }

  async function faultToJustification(
    values: typeof faultJustificationForm.values,
  ) {
    if (selectedAppointment) {
      await faultToJustificationFetcher({
        id: selectedAppointment?.id,
        data: {
          justification: values.justification,
          description: values.description,
          date: [values.begin.toISOString(), values.finish.toISOString()],
        },
        onSuccess: () => {
          faultJustificationForm.reset();
          setPageModalVisible(null);
          getFaultWorks();
        },
      });
    }
  }

  async function getOverIntervalWorks() {
    const validatedValues: any = {};

    Object.entries(filterForm.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedValues[key] = val;
      }
    });

    await getOverIntervalFetcher({
      query: {
        ...validatedValues,
        page: currentPage.current,
        limit: currentPageLimit.current,
        scale: Number(filterForm.values.scale),
        date: validatedValues?.date
          ? validatedValues.date.join(',')
          : undefined,
      },
    });
  }

  useEffect(() => {
    getUsersFilter();
    getWorkScale();
  }, []);

  return (
    <Page title="Apontamentos">
      <CustomLoader
        loading={
          getLateWorksLoader ||
          getUserFiltersLoader ||
          getWorkScaleLoading ||
          getFaultWorksLoader ||
          editAppointmentsLoader ||
          faultToJustificationLoader
        }
      />
      <Flex wrap="wrap" justify="start" mb={8}>
        <form onSubmit={filterForm.onSubmit(() => handleFilter())}>
          <Flex align="center">
            <Select
              withAsterisk
              required
              clearable
              label="Escala"
              name="workScale"
              placeholder="selecione a escala"
              data={
                getWorkScaleData?.map((item) => ({
                  label: item.name,
                  value: String(item.id),
                })) ?? []
              }
              mb={16}
              mr={8}
              {...filterForm.getInputProps('scale')}
            />
            <Select
              searchable
              clearable
              label="Usuário"
              placeholder="selecione o usuário"
              data={
                getUserFiltersData?.map((item) => ({
                  label: item.username,
                  value: String(item.id),
                })) ?? []
              }
              mb={16}
              mr={8}
              {...filterForm.getInputProps('user')}
            />
            <CustomDateRangePicker
              withAsterisk
              required
              allowSingleDateInRange
              label="Criado em"
              placeholder="selecione um intervalo"
              mr={8}
              {...filterForm.getInputProps('date')}
            />
            <Button mb={-10} type="submit">
              Filtrar
            </Button>
            <Button
              onClick={handleClear}
              variant="outline"
              ml={8}
              mb={-10}
              type="button"
            >
              Limpar
            </Button>
          </Flex>
        </form>
      </Flex>
      <Tabs
        keepMounted={false}
        variant="outline"
        value={activeTab}
        onTabChange={(value) => setActiveTab(value as any)}
      >
        <Tabs.List>
          <Tabs.Tab value="late" icon={<IconClock size={14} />}>
            Atrasos
          </Tabs.Tab>
          <Tabs.Tab
            value="over-interval"
            icon={<IconAlertTriangle size={14} />}
          >
            Intervalos
          </Tabs.Tab>
          <Tabs.Tab value="fault" icon={<IconAlertTriangle size={14} />}>
            Faltas
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="late" pt="xs">
          <DataTable
            recordsPerPage={
              getLateWorksData?.meta.itemsPerPage ?? currentPageLimit.current
            }
            recordsPerPageOptions={[10, 50, 100, 500]}
            onRecordsPerPageChange={(recordRange) =>
              handleChangeLimit(recordRange)
            }
            onPageChange={handlePaginate}
            page={currentPage.current}
            totalRecords={getLateWorksData?.meta.totalItems}
            fetching={getLateWorksLoader}
            minHeight={500}
            noRecordsText="Sem horários"
            withBorder
            borderRadius="sm"
            striped
            highlightOnHover
            records={getLateWorksData?.items}
            columns={[
              {
                accessor: 'username',
                title: 'Funcionário',
              },
              {
                accessor: 'dayOfWeek',
                title: 'Dia',
              },
              {
                accessor: 'date',
                title: 'Data',
              },
              {
                accessor: 'begin',
                title: 'Entrada',
                render: ({ begin }) =>
                  dayjsPlugins(begin).isValid()
                    ? formatLocale(begin, 'HH:mm:ss')
                    : '--',
              },
              {
                accessor: 'startLounch',
                title: 'Inicio Intervalo',
                render: ({ startLounch }) =>
                  dayjsPlugins(startLounch).isValid()
                    ? formatLocale(startLounch, 'HH:mm:ss')
                    : '--',
              },
              {
                accessor: 'endLounch',
                title: 'Fim Intervalo',
                render: ({ endLounch }) =>
                  dayjsPlugins(endLounch).isValid()
                    ? formatLocale(endLounch, 'HH:mm:ss')
                    : '--',
              },
              {
                accessor: 'finish',
                title: 'Saída',
                render: ({ finish }) =>
                  dayjsPlugins(finish).isValid()
                    ? formatLocale(finish, 'HH:mm:ss')
                    : '--',
              },
              {
                accessor: 'workTime',
                title: 'Horas Trabalhadas',
                render: ({ workTime }) =>
                  secondsToTime(workTime).toFormatedTime(),
              },
              {
                accessor: 'scaleTime',
                title: 'Base Carga Horária',
                render: ({ scaleTime }) =>
                  secondsToTime(scaleTime).toFormatedTime(),
              },
              {
                accessor: 'accumulatorTime',
                title: 'Saldo',
                render: ({ accumulatorTime }) =>
                  secondsToTime(accumulatorTime).toFormatedTime(),
              },
              {
                accessor: 'description',
                title: 'Descrição',
              },
              {
                accessor: 'daily-word-edit-menu',
                title: '',
                render: (item) => (
                  <Menu>
                    <Menu.Target>
                      <Button color="blue" variant="subtle" w={40} p={0}>
                        <IconDotsVertical />
                      </Button>
                    </Menu.Target>
                    <Menu.Dropdown style={{ position: 'absolute' }}>
                      <Menu.Item
                        onClick={() => selectAppointment(item)}
                        icon={<IconEdit size={14} />}
                      >
                        Editar
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
        </Tabs.Panel>
        <Tabs.Panel value="over-interval" pt="xs">
          <DataTable
            recordsPerPage={
              getOverIntervalData?.meta.itemsPerPage ?? currentPageLimit.current
            }
            recordsPerPageOptions={[10, 50, 100, 500]}
            onRecordsPerPageChange={(recordRange) =>
              handleChangeLimit(recordRange)
            }
            onPageChange={handlePaginate}
            page={currentPage.current}
            totalRecords={getOverIntervalData?.meta.totalItems}
            fetching={getOverIntervalLoading}
            minHeight={500}
            noRecordsText="Sem horários"
            withBorder
            borderRadius="sm"
            striped
            highlightOnHover
            records={getOverIntervalData?.items}
            columns={[
              {
                accessor: 'username',
                title: 'Funcionário',
              },
              {
                accessor: 'dayOfWeek',
                title: 'Dia',
              },
              {
                accessor: 'date',
                title: 'Data',
              },
              {
                accessor: 'begin',
                title: 'Entrada',
                render: ({ begin }) =>
                  dayjsPlugins(begin).isValid()
                    ? formatLocale(begin, 'HH:mm:ss')
                    : '--',
              },
              {
                accessor: 'startLounch',
                title: 'Inicio Intervalo',
                render: ({ startLounch }) =>
                  dayjsPlugins(startLounch).isValid()
                    ? formatLocale(startLounch, 'HH:mm:ss')
                    : '--',
              },
              {
                accessor: 'endLounch',
                title: 'Fim Intervalo',
                render: ({ endLounch }) =>
                  dayjsPlugins(endLounch).isValid()
                    ? formatLocale(endLounch, 'HH:mm:ss')
                    : '--',
              },
              {
                accessor: 'finish',
                title: 'Saída',
                render: ({ finish }) =>
                  dayjsPlugins(finish).isValid()
                    ? formatLocale(finish, 'HH:mm:ss')
                    : '--',
              },
              {
                accessor: 'workTime',
                title: 'Horas Trabalhadas',
                render: ({ workTime }) =>
                  secondsToTime(workTime).toFormatedTime(),
              },
              {
                accessor: 'scaleTime',
                title: 'Base Carga Horária',
                render: ({ scaleTime }) =>
                  secondsToTime(scaleTime).toFormatedTime(),
              },
              {
                accessor: 'accumulatorTime',
                title: 'Saldo',
                render: ({ accumulatorTime }) =>
                  secondsToTime(accumulatorTime).toFormatedTime(),
              },
              {
                accessor: 'description',
                title: 'Descrição',
              },
              {
                accessor: 'daily-word-edit-menu',
                title: '',
                render: (item) => (
                  <Menu>
                    <Menu.Target>
                      <Button color="blue" variant="subtle" w={40} p={0}>
                        <IconDotsVertical />
                      </Button>
                    </Menu.Target>
                    <Menu.Dropdown style={{ position: 'absolute' }}>
                      <Menu.Item
                        onClick={() => selectAppointment(item)}
                        icon={<IconEdit size={14} />}
                      >
                        Editar
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
        </Tabs.Panel>
        <Tabs.Panel value="fault" pt="xs">
          <DataTable
            recordsPerPage={
              getFaultWorksData?.meta.itemsPerPage ?? currentPageLimit.current
            }
            recordsPerPageOptions={[10, 50, 100, 500]}
            onRecordsPerPageChange={(recordRange) =>
              handleChangeLimit(recordRange)
            }
            onPageChange={handlePaginate}
            page={currentPage.current}
            totalRecords={getFaultWorksData?.meta.totalItems}
            fetching={getFaultWorksLoader}
            minHeight={500}
            noRecordsText="Sem horários"
            withBorder
            borderRadius="sm"
            striped
            highlightOnHover
            records={getFaultWorksData?.items}
            columns={[
              {
                accessor: 'username',
                title: 'Funcionário',
              },
              {
                accessor: 'dayOfWeek',
                title: 'Dia',
              },
              {
                accessor: 'date',
                title: 'Data',
                render: ({ date }) =>
                  dayjsPlugins(date).isValid()
                    ? formatLocale(date, 'DD/MM/YYYY')
                    : '--',
              },
              {
                accessor: 'begin',
                title: 'Entrada',
                render: ({ begin }) =>
                  dayjsPlugins(begin).isValid()
                    ? formatLocale(begin, 'HH:mm:ss')
                    : '--',
              },
              {
                accessor: 'startLounch',
                title: 'Inicio Intervalo',
                render: ({ startLounch }) =>
                  dayjsPlugins(startLounch).isValid()
                    ? formatLocale(startLounch, 'HH:mm:ss')
                    : '--',
              },
              {
                accessor: 'endLounch',
                title: 'Fim Intervalo',
                render: ({ endLounch }) =>
                  dayjsPlugins(endLounch).isValid()
                    ? formatLocale(endLounch, 'HH:mm:ss')
                    : '--',
              },
              {
                accessor: 'finish',
                title: 'Saída',
                render: ({ finish }) =>
                  dayjsPlugins(finish).isValid()
                    ? formatLocale(finish, 'HH:mm:ss')
                    : '--',
              },
              {
                accessor: 'workTime',
                title: 'Horas Trabalhadas',
                render: ({ workTime }) =>
                  secondsToTime(workTime).toFormatedTime(),
              },
              {
                accessor: 'scaleTime',
                title: 'Base Carga Horária',
                render: ({ scaleTime }) =>
                  secondsToTime(scaleTime).toFormatedTime(),
              },
              {
                accessor: 'accumulatorTime',
                title: 'Saldo',
                render: ({ accumulatorTime }) =>
                  secondsToTime(accumulatorTime).toFormatedTime(),
              },
              {
                accessor: 'description',
                title: 'Descrição',
              },
              {
                accessor: 'daily-word-edit-menu',
                title: '',
                render: (item) => (
                  <Menu>
                    <Menu.Target>
                      <Button color="blue" variant="subtle" w={40} p={0}>
                        <IconDotsVertical />
                      </Button>
                    </Menu.Target>
                    <Menu.Dropdown style={{ position: 'absolute' }}>
                      <Menu.Item
                        onClick={() => setFaultToJustification(item)}
                        icon={<IconFile size={14} />}
                      >
                        Justificar
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
        </Tabs.Panel>
      </Tabs>
      <Modal
        size={360}
        title={<Text fw="bold">Adicionar Justificativa</Text>}
        opened={pageModalVisible === 'edit-appointment'}
        onClose={() => {
          setPageModalVisible(null);
        }}
        closeOnClickOutside={false}
        closeOnEscape={false}
      >
        <form
          onSubmit={editAppointmentForm.onSubmit(
            (values) =>
              selectedAppointment &&
              handleEditAppointment(selectedAppointment?.id, values),
          )}
        >
          <Grid columns={2} mb={16}>
            <Grid.Col span={2}>
              <TimeInput
                withSeconds
                label="Horas Trabalhadas"
                icon={<IconClock size={16} />}
                clearable
                value={editAppointmentForm.values.workTimeDate}
                onChange={(value) => {
                  if (value) {
                    editAppointmentForm.setFieldValue(
                      'workTime',
                      dayjsPlugins(value).format('HH:mm:ss'),
                    );
                    editAppointmentForm.setFieldValue('workTimeDate', value);
                  }
                }}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Flex align="end">
                <TimeInput
                  withSeconds
                  w={200}
                  mr={8}
                  label="Saldo"
                  icon={<IconClock size={16} />}
                  clearable
                  value={editAppointmentForm.values.accumulatorDate}
                  onChange={(value) => {
                    if (value) {
                      editAppointmentForm.setFieldValue(
                        'accumulator',
                        dayjsPlugins(value).format('HH:mm:ss'),
                      );
                      editAppointmentForm.setFieldValue(
                        'accumulatorDate',
                        value,
                      );
                    }
                  }}
                />
                <Switch
                  size="lg"
                  onLabel="Positivo"
                  offLabel="Negativo"
                  checked={editAppointmentForm.values.accumulatorPositive}
                  onChange={(e) =>
                    editAppointmentForm.setFieldValue(
                      'accumulatorPositive',
                      e.currentTarget.checked,
                    )
                  }
                />
              </Flex>
            </Grid.Col>
            <Grid.Col span={2}>
              <TextInput
                required
                withAsterisk
                label="Descrição"
                placeholder="digite a descrição"
                type="text"
                {...editAppointmentForm.getInputProps('description')}
              />
            </Grid.Col>
          </Grid>
          <Group position="right">
            <Button type="submit">Salvar</Button>
          </Group>
        </form>
      </Modal>
      <Modal
        size={360}
        title={<Text fw="bold">Justificar Falta</Text>}
        opened={pageModalVisible === 'fault-justification'}
        onClose={() => {
          setPageModalVisible(null);
        }}
        closeOnClickOutside={false}
        closeOnEscape={false}
      >
        <form onSubmit={faultJustificationForm.onSubmit(faultToJustification)}>
          <Grid columns={2} mb={16}>
            <Grid.Col span={2}>
              <Select
                withAsterisk
                required
                clearable
                label="Justificativa"
                placeholder="selecione a justificativa"
                data={faultJustification}
                {...faultJustificationForm.getInputProps('justification')}
                mb={16}
                mr={8}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <CustomDateTimePicker
                label="Inicio"
                canChangeDate={false}
                date={faultJustificationForm.values.begin}
                onChange={(dateTime) => {
                  faultJustificationForm.setFieldValue('begin', dateTime);
                }}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <CustomDateTimePicker
                label="Fim"
                canChangeDate={false}
                date={faultJustificationForm.values.finish}
                onChange={(dateTime) => {
                  faultJustificationForm.setFieldValue('finish', dateTime);
                }}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Tooltip label="este tempo é aquele que não será descontado do usuário">
                <Flex>
                  <Text size={14} mr={8}>
                    Tempo Abonado
                  </Text>
                  <IconHelp size={20} />
                </Flex>
              </Tooltip>
              <Badge
                color={faultJustificationTime.hours <= 0 ? 'red' : 'green'}
                size="lg"
              >
                {faultJustificationTime.toFormatedTime()}
              </Badge>
            </Grid.Col>
            <Grid.Col span={2}>
              <Textarea
                label="Observações"
                placeholder="obs sobre justificativa"
                {...faultJustificationForm.getInputProps('description')}
              />
            </Grid.Col>
          </Grid>
          <Group position="right">
            <Button type="submit">Salvar</Button>
          </Group>
        </form>
      </Modal>
    </Page>
  );
}

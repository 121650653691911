import { NavLink, ThemeIcon } from '@mantine/core';
import { IconMoneybag } from '@tabler/icons';
import { AppRoutePaths } from '../../../utils/enums/routes';

interface CashFlowLinkProps {
  navigate(route: string): void;
}

export function CashFlowLink({ navigate }: CashFlowLinkProps) {
  return (
    <NavLink
      styles={{
        label: {
          color: '#373A40',
        },
      }}
      label="Caixa"
      fw="bold"
      color=""
      icon={
        <ThemeIcon color="green.8" variant="light">
          <IconMoneybag size={16} />
        </ThemeIcon>
      }
      childrenOffset={28}
    >
      <NavLink
        label="Movimentação"
        onClick={() => navigate(AppRoutePaths.CASH_FLOW)}
      />
    </NavLink>
  );
}

import { useState } from 'react';
import { User } from '../models/user';
import { LocalStorageKeys } from '../utils/enums/local-storage';
import { useLocalStorage } from './useLocalStorage';

export interface AuthUser {
  user: User;
  authToken?: string;
}

export const useUser = () => {
  const { setItem, getItem, removeItem } = useLocalStorage();
  // const { user, login, logout } = useContext(AuthContext);
  const [user, setUser] = useState<AuthUser | null>(() => {
    const authUser = getItem(LocalStorageKeys.LOGGED_USER);
    if (authUser) {
      return JSON.parse(authUser);
    }

    return null;
  });

  const addUser = (user: AuthUser) => {
    // setUser(user);
    setItem(LocalStorageKeys.LOGGED_USER, JSON.stringify(user));
    setUser(user);
  };

  const removeUser = () => {
    // setUser(null);
    removeItem(LocalStorageKeys.LOGGED_USER);
    setUser(null);
  };

  return { user, addUser, removeUser };
};

import { NavLink, ThemeIcon } from '@mantine/core';
import { IconCashBanknote } from '@tabler/icons';
import { AppRoutePaths } from '../../../utils/enums/routes';

interface UserLinkProps {
  navigate(route: string): void;
}

export function HourHistoryLink({ navigate }: UserLinkProps) {
  return (
    <NavLink
      styles={{
        label: {
          color: '#373A40',
        },
      }}
      label="Lista de Pagamentos"
      fw="bold"
      color=""
      icon={
        <ThemeIcon color="yellow" variant="light">
          <IconCashBanknote size={16} />
        </ThemeIcon>
      }
      childrenOffset={28}
    >
      <NavLink
        label="Diárias"
        onClick={() => navigate(AppRoutePaths.WORK_HISTORY)}
      />
    </NavLink>
  );
}

import { Button, DefaultMantineColor, ThemeIcon } from '@mantine/core';

interface NavButtonProps {
  icon: React.ReactNode;
  color: DefaultMantineColor;
  text: string;
  onClick: () => void;
}

export function NavButton({ icon, color, text, onClick }: NavButtonProps) {
  return (
    <Button
      onClick={onClick}
      color="dark.4"
      styles={{
        inner: {
          justifyContent: 'start',
        },
        root: {
          '&:hover': {
            backgroundColor: 'rgba(193, 194, 197, 0.30)',
          },
        },
      }}
      leftIcon={
        <ThemeIcon color={color} variant="light">
          {icon}
        </ThemeIcon>
      }
      variant="subtle"
    >
      {text}
    </Button>
  );
}

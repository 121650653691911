import { Flex, Title } from '@mantine/core';

interface PageProps {
  children: React.ReactNode;
  title?: string;
}

export function Page({ children, title }: PageProps) {
  return (
    <Flex
      direction="column"
      style={{
        padding: '1rem 0',
        height: '100%',
        position: 'relative',
      }}
    >
      <Title ml={16} size={22}>
        {title}
      </Title>
      {children}
    </Flex>
  );
}

import { AxiosResponse } from 'axios';
import { WorkScaleData } from '../@types/work-scale';
import { WorkScale } from '../models/work-scale';
import { api } from '../providers/base-api';

export type GetWorkScaleQuery = {
  name?: string;
};

export async function getWorkScaleRequest(
  query?: GetWorkScaleQuery,
): Promise<AxiosResponse<WorkScale[]>> {
  const response = await api.get<WorkScale[]>('/work-scale', {
    params: query,
  });
  return response;
}

export async function showWorkScale(id: number): Promise<WorkScale> {
  const response = await api.get<WorkScale>(`/work-scale/${id}`);
  return response.data;
}

export async function createWorkScaleRequest(
  data: WorkScaleData,
): Promise<WorkScale> {
  const response = await api.post<WorkScale>('/work-scale', data);
  return response.data;
}

export async function updateWorkScaleRequest(
  id: number,
  data: WorkScaleData,
): Promise<WorkScale> {
  const response = await api.put<WorkScale>(`/work-scale/${id}`, data);
  return response.data;
}

import { createContext, Dispatch, SetStateAction } from 'react';
import { AuthLoginData } from '../@types/auth';
import { useAuth } from '../hooks/useAuth';
import { AuthUser } from '../hooks/useUser';

interface AuthContext {
  user: AuthUser | null;
  login: (data: AuthLoginData) => void;
  logout: () => void;
  loading: boolean;
  biometricLogin: () => void;
  biometricAttempt: number;
  setBiometricAttempt: Dispatch<SetStateAction<number>>;
}

export const AuthContext = createContext<AuthContext>({
  user: null,
  login: () => {},
  logout: () => {},
  biometricLogin: () => {},
  loading: false,
  biometricAttempt: 0,
  setBiometricAttempt: () => {},
});

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    login,
    logout,
    user,
    loading,
    biometricLogin,
    biometricAttempt,
    setBiometricAttempt,
  } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        user,
        loading,
        biometricLogin,
        biometricAttempt,
        setBiometricAttempt,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

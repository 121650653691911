import { Grid, Group, Paper, Skeleton, Text, Title } from '@mantine/core';
import { IconCash, IconLock } from '@tabler/icons';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../components/page';
import { useAuth } from '../../hooks/useAuth';
import useCashFlowContext from '../../hooks/useCashFlowContext';
import { UserRole, UserType } from '../../models/user';
import { AppRoutePaths } from '../../utils/enums/routes';
import { formatBRL } from '../../utils/helpers';

export function Home() {
  const { balance, getBalance } = useCashFlowContext();
  const { user } = useAuth();
  const navigate = useNavigate();
  const canViewCashFlow =
    user?.user.role === UserRole.MANAGER_CREATOR ||
    user?.user.type === UserType.MASTER;

  const converterBalance = Number(balance);

  const BlockedPaper = () => {
    return (
      <Paper bg="gray.1" withBorder p={16}>
        <Group>
          <IconLock color="gray" size={30} />
          <Title color="gray" size={25}>
            Indisponivel
          </Title>
        </Group>
        <Group>
          <Skeleton height={8} radius="md" />
          <Skeleton height={8} radius="md" />
        </Group>
      </Paper>
    );
  };

  useEffect(() => {
    getBalance();
  }, []);

  return (
    <Page>
      <Title size={25}>Painel Inicial</Title>
      <Grid columns={4}>
        <Grid.Col span={1}>
          {canViewCashFlow ? (
            <Paper
              onClick={() => navigate(AppRoutePaths.CASH_FLOW)}
              style={{ cursor: 'pointer' }}
              shadow="md"
              p={16}
            >
              <Text fw="bold" size={25}>
                Caixa
              </Text>
              <Group>
                <IconCash size={30} />
                <Text>Saldo: </Text>
                <Text
                  color={converterBalance > 0 ? 'green.8' : 'red.8'}
                  fw="bold"
                >
                  {`${formatBRL(converterBalance.toFixed(2))}`}
                </Text>
              </Group>
            </Paper>
          ) : (
            <BlockedPaper />
          )}
        </Grid.Col>
        <Grid.Col span={1}>
          <BlockedPaper />
        </Grid.Col>
        <Grid.Col span={1}>
          <BlockedPaper />
        </Grid.Col>
        <Grid.Col span={1}>
          <BlockedPaper />
        </Grid.Col>
      </Grid>
    </Page>
  );
}

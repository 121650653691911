import { AxiosResponse } from 'axios';
import { CreateBankHourData } from '../@types/bank-hour';
import { BankHour } from '../models/bank-hour';
import { api } from '../providers/base-api';
import { PaginatedResponse } from '../utils/types/data/pagination';

export type GetBankHourPaginatedQuery = {
  page: number;
  limit: number;
  username?: string;
  date?: string;
};

export async function createBankHourRequest(
  data: CreateBankHourData,
): Promise<BankHour> {
  const response = await api.post<BankHour>('/bank-hours', data);

  return response.data;
}

export async function getBankHoursRequest(
  params: GetBankHourPaginatedQuery,
): Promise<AxiosResponse<PaginatedResponse<BankHour>>> {
  const response = await api.get<PaginatedResponse<BankHour>>('/bank-hours', {
    params,
  });

  return response;
}

export async function removeBankHourRequest(
  id: number,
): Promise<AxiosResponse<void>> {
  const response = await api.delete(`/bank-hours/${id}`);

  return response;
}

import { EmployeePeriod } from '../../models/employees';

export const employeePeriodList = [
  {
    value: EmployeePeriod.DAY,
    label: 'Diurno',
  },
  {
    value: EmployeePeriod.NIGHT,
    label: 'Noturno',
  },
];

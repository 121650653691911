import { useState } from 'react';
import { useGetCashFlowBalance } from '../data/hooks/cash-flow';
import { LocalStorageKeys } from '../utils/enums/local-storage';
import { useLocalStorage } from './useLocalStorage';

export interface CashFlowState {
  balance: string;
}

export const useCashFlow = () => {
  const { setItem, getItem, removeItem } = useLocalStorage();
  const { fetch: getCashFlowBalanceFetcher } = useGetCashFlowBalance();

  const [cashFlow, setCashFlow] = useState<CashFlowState>(() => {
    const cashFlow = getItem(LocalStorageKeys.CASH_FLOW);
    if (cashFlow) {
      return JSON.parse(cashFlow);
    }

    return { balance: '' };
  });

  const getBalance = async () => {
    await getCashFlowBalanceFetcher({
      onSuccess: (res) => {
        if (res?.balance) {
          set(res);
        }
      },
    });
  };

  const set = (cashFlow: CashFlowState) => {
    setItem(LocalStorageKeys.CASH_FLOW, JSON.stringify(cashFlow));
    setCashFlow(cashFlow);
  };

  const remove = () => {
    removeItem(LocalStorageKeys.CASH_FLOW);
    setCashFlow({ balance: '' });
  };

  return { cashFlow, set, remove, getBalance };
};

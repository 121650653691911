import 'dayjs/locale/pt-br';
import { Calendar } from '@mantine/dates';
import { Button, Container, Group } from '@mantine/core';

interface CustomCalendarProps {
  value: Date[];
  minDate?: Date;
  onChange(value: Date[]): void;
}

export function CustomCalendar(props: CustomCalendarProps) {
  return (
    <Container>
      <Calendar locale="pt-br" multiple {...props} />
      <Group position="left">
        <Button onClick={() => props.onChange([])} variant="subtle">
          limpar
        </Button>
      </Group>
    </Container>
  );
}

import {
  Button,
  Center,
  Flex,
  Image,
  Loader,
  Modal,
  Text,
  TextInput,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  IconFingerprint,
  IconFingerprintOff,
  IconMail,
  IconUserOff,
} from '@tabler/icons';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FingerprintImage from '../../assets/fingerprint-image.svg';
import { Page } from '../../components/page';
import { loadToMemoryDevice, transferBiometric } from '../../data/biometric';
import useAuthContext from '../../hooks/useAuthContext';
import { useBiometric } from '../../hooks/useBiometric';
import { Biometric } from '../../models/biometric';
import { UserRole, UserType } from '../../models/user';
import { errorNotification } from '../../providers/mantine-notifications';
import { AppRoutePaths } from '../../utils/enums/routes';

type PageModalState =
  | 'transfer-biometric'
  | 'attempt-biometric'
  | 'not-found-biometric'
  | null;

export function LoginBiometric() {
  const navigation = useNavigate();
  const {
    loading,
    user,
    biometricLogin,
    biometricAttempt,
    setBiometricAttempt,
  } = useAuthContext();
  const [pageModal, setPageModal] = useState<PageModalState>(null);
  const [transferBiometricLoader, setTransferBiometricLoader] =
    useState<boolean>(false);
  const [currentBiometric, setCurrentBiometric] = useState<boolean>(false);
  const hasEffectRun = useRef(false);
  const {
    createDeviceMemory,
    addBiometricOnStorage,
    loading: createDeviceMemoryLoading,
  } = useBiometric();

  const formTransferBiometric = useForm({
    initialValues: {
      accessCode: '',
    },
  });

  const handleTransferBiometricSubmit = async ({
    accessCode,
  }: typeof formTransferBiometric.values) => {
    try {
      setTransferBiometricLoader(true);
      const biometric: Biometric = await transferBiometric(accessCode);
      addBiometricOnStorage(biometric);
      setTimeout(async () => {
        loadToMemoryDevice([
          { id: biometric.user, template: biometric.template },
        ])
          .then((resp) => {
            if (resp.success) {
              setPageModal(null);
              setCurrentBiometric(resp.success);
              biometricLogin();
              return;
            }
          })
          .catch(() => {
            handleBackNotFoundUser();
            errorNotification({
              title: 'Biometria já existente!',
              message: 'tente outro código de acesso.',
            });
          });

        setTransferBiometricLoader(false);
      }, 3000);
    } catch (error) {
      setTransferBiometricLoader(false);
      setPageModal('not-found-biometric');
      errorNotification({
        title: 'Usuário não encontrado',
        message: 'tente novamente.',
      });
    }
  };

  const handleCloseModal = () => {
    setCurrentBiometric(false);
    setBiometricAttempt(0);
    setPageModal(null);
  };

  const handleBackNotFoundUser = () => {
    setPageModal(null);
    setPageModal('transfer-biometric');
  };

  useEffect(() => {
    if (
      user?.user.role === UserRole.EMPLOYEE ||
      user?.user.role === UserRole.MONTHLY_EMPLOYEE ||
      user?.user.role === UserRole.DAILY_EMPLOYEE
    ) {
      navigation({ pathname: AppRoutePaths.WORK_HISTORY_CREATE });
    } else if (
      user?.user.type === UserType.MASTER ||
      user?.user.type === UserType.MANAGER
    ) {
      navigation({ pathname: AppRoutePaths.HOME });
    }
  }, []);

  useEffect(() => {
    if (biometricAttempt === 3) {
      setPageModal('transfer-biometric');
    } else if (currentBiometric && biometricAttempt >= 4) {
      setPageModal('attempt-biometric');
    } else if (biometricAttempt >= 4 && !currentBiometric) {
      setPageModal('transfer-biometric');
    }
  }, [biometricAttempt]);

  useEffect(() => {
    if (hasEffectRun.current) return;
    createDeviceMemory();
    hasEffectRun.current = true;
  }, []);

  return (
    <Page>
      <Center mt="100px">
        <Flex direction={'column'} align={'center'} gap={'xl'}>
          <Flex direction={'column'} align={'center'} gap={'xs'}>
            <Image src={FingerprintImage} style={{ width: 140 }} />
            <Title order={2}>Login</Title>
          </Flex>
          <Flex direction={'column'} align={'center'} gap={'xs'}>
            <Button
              onClick={() => biometricLogin()}
              size="md"
              color="indigo"
              pr="50px"
              pl="50px"
              loading={loading || createDeviceMemoryLoading}
            >
              Iniciar
            </Button>
            <Text size={'sm'} c="dimmed">
              Clique para iniciar leitura
            </Text>
          </Flex>
          <Button
            onClick={() => setPageModal('transfer-biometric')}
            size="md"
            variant="outline"
            color="indigo"
            pr="50px"
            pl="50px"
            loading={loading || createDeviceMemoryLoading}
          >
            Não consegue acessar?
          </Button>
        </Flex>
      </Center>
      <Modal
        opened={pageModal === 'transfer-biometric'}
        onClose={() => handleCloseModal()}
        closeOnClickOutside={false}
        closeOnEscape={false}
        size="30%"
      >
        {transferBiometricLoader ? (
          <Flex direction={'column'} align={'center'}>
            <Loader mb={'xs'} size={'lg'} />
            <Text fw={'bold'} size={'lg'}>
              Transferindo biometria digital
            </Text>
          </Flex>
        ) : (
          <Flex direction={'column'}>
            <Text fw={'bold'} size={'lg'}>
              Sua biometria digital não foi encontrada
            </Text>

            <Text size={'sm'} mb={'xs'}>
              Digite o seu código de acesso para que possamos encontrar sua
              biometria
            </Text>

            <form
              onSubmit={formTransferBiometric.onSubmit((values) =>
                handleTransferBiometricSubmit(values),
              )}
            >
              <Flex direction={'column'}>
                <TextInput
                  autoComplete="off"
                  autoSave="off"
                  required
                  withAsterisk
                  label="Código de acesso"
                  placeholder="ex: CAS143S"
                  {...formTransferBiometric.getInputProps('accessCode')}
                  rightSection={
                    <ThemeIcon color="orange" variant="light">
                      <IconMail size={16} />
                    </ThemeIcon>
                  }
                  mb={16}
                />
                <Button
                  type="submit"
                  size="sm"
                  color="ltpBlue.5"
                  leftIcon={<IconFingerprint />}
                >
                  Transferir
                </Button>
              </Flex>
            </form>
          </Flex>
        )}
      </Modal>
      <Modal
        opened={pageModal === 'attempt-biometric'}
        onClose={() => handleCloseModal()}
        closeOnClickOutside={false}
        closeOnEscape={false}
        size="30%"
      >
        <Flex direction={'column'}>
          <Flex
            direction={'column'}
            gap={'xs'}
            justify={'center'}
            align={'center'}
          >
            <IconFingerprintOff width={'80'} height={'80'} />
            <Text mb={'xs'} fw={'bold'} size={'md'}>
              Dedo não reconhecido. Certifique-se de utilizar o dedo correto
              para a verificação.
            </Text>
          </Flex>

          <Button
            mt={'xs'}
            type="button"
            size="lg"
            color="ltpBlue.9"
            leftIcon={<IconFingerprint />}
            onClick={() => biometricLogin()}
            loading={loading}
          >
            Tentar novamente
          </Button>
        </Flex>
      </Modal>
      <Modal
        opened={pageModal === 'not-found-biometric'}
        onClose={() => handleCloseModal()}
        size="30%"
        withCloseButton={false}
      >
        <Flex direction={'column'}>
          <Flex
            direction={'column'}
            gap={'xs'}
            justify={'center'}
            align={'center'}
          >
            <IconUserOff width={'60'} height={'60'} />
            <Text mb={'xs'} fw={'bold'} size={'md'}>
              Biometria ou Usuário não encontrados, dirija-se ao setor do RH
            </Text>
          </Flex>
          <Button
            type="button"
            size="lg"
            color="ltpBlue.9"
            onClick={() => handleBackNotFoundUser()}
          >
            Voltar
          </Button>
        </Flex>
      </Modal>
    </Page>
  );
}

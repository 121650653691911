import { CreateEmployeeData } from '../@types/employee';
import { Employee } from '../models/employees';
import { api } from '../providers/base-api';

export async function createEmployeeRequest(
  userId: number,
  data: CreateEmployeeData,
): Promise<Employee> {
  const response = await api.post<Employee>(`/employees/${userId}`, data);

  return response.data;
}

export async function showEmployeeRequest(userId: number): Promise<Employee> {
  const response = await api.get<Employee>(`/employees/${userId}`);

  return response.data;
}

export async function updateEmployeeRequest(
  userId: number,
  data: CreateEmployeeData,
): Promise<Employee> {
  const response = await api.put<Employee>(`/employees/${userId}`, data);

  return response.data;
}

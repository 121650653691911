/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import { Button, Flex, Menu, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCirclePlus, IconDotsVertical, IconEdit } from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect } from 'react';
import { rootNavigate } from '../../components/custom-router';
import { Page } from '../../components/page';
import { useGetWorkScaleList } from '../../data/hooks/work-scale';
import { AppRoutePaths } from '../../utils/enums/routes';
import { formatDateTime } from '../../utils/helpers';

export function WorkScaleList() {
  const {
    fetch: getWorkScaleFetcher,
    response: getWorkScaleData,
    loading: getWorkScaleLoading,
  } = useGetWorkScaleList();

  const formFilter = useForm({
    initialValues: {
      name: '',
    },
  });

  function handleClearFilter() {
    formFilter.reset();
    getWorkScales(true);
  }

  async function getWorkScales(reset = false) {
    const validatedValues: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedValues[key] = val;
      }
    });

    await getWorkScaleFetcher({
      query: {
        ...validatedValues,
      },
    });
  }

  useEffect(() => {
    getWorkScales();
  }, []);

  return (
    <Page title="Escalas">
      <Flex m={16} direction="column">
        <Flex align="center" justify="end" mb={8}>
          <Button
            mr={8}
            color="ltpBlue.9"
            leftIcon={<IconCirclePlus />}
            onClick={() => rootNavigate(AppRoutePaths.WORK_SCALE_CREATE)}
          >
            Novo
          </Button>
        </Flex>
        <Flex wrap="wrap" justify="start" mb={8}>
          <form onSubmit={formFilter.onSubmit(() => getWorkScales())}>
            <Flex align="center">
              <TextInput
                label="Nome"
                placeholder="digite o nome"
                mb={16}
                mr={8}
                type="text"
                name="name"
                {...formFilter.getInputProps('name')}
              />
              <Button mb={-10} type="submit">
                Filtrar
              </Button>
              <Button
                mb={-10}
                ml={10}
                onClick={handleClearFilter}
                color="ltpBlue.9"
                variant="outline"
              >
                Limpar
              </Button>
            </Flex>
          </form>
        </Flex>
        <DataTable
          fetching={getWorkScaleLoading}
          height="56vh"
          noRecordsText="Sem escalas"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          records={getWorkScaleData}
          columns={[
            {
              accessor: 'name',
              title: 'Nome',
              width: 300,
            },
            {
              accessor: 'createdBy',
              title: 'Criado por',
            },
            {
              accessor: 'createdAt',
              title: 'Criado em',
              render: ({ createdAt }) => formatDateTime(createdAt),
            },
            {
              accessor: 'work-scale-table-menu',
              title: '',
              render: ({ id }) => (
                <Menu>
                  <Menu.Target>
                    <Button color="blue" variant="subtle" w={40} p={0}>
                      <IconDotsVertical />
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown style={{ position: 'absolute' }}>
                    <Menu.Item
                      icon={<IconEdit size={14} />}
                      onClick={() => rootNavigate(`work-scale/edit/${id}`)}
                    >
                      Editar
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              ),
            },
          ]}
        />
      </Flex>
    </Page>
  );
}

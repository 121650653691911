export function formatCPF(value: string) {
  if (!value) {
    return '';
  }
  value = value.replace(/\D/g, '');

  if (value.length > 11) {
    value = value.slice(0, -1);
  }

  value = value.replace(/(\d{3})(\d)/, '$1.$2');
  value = value.replace(/(\d{3})(\d)/, '$1.$2');
  value = value.replace(/(\d{3})(\d)/, '$1-$2');

  return value;
}

export function formatRG(value: string) {
  if (!value) {
    return '';
  }
  value = value.replace(/\D/g, '');

  if (value.length > 9) {
    value = value.slice(0, -1);
  }

  value = value.replace(/(\d{2})(\d)/, '$1.$2');
  value = value.replace(/(\d{3})(\d)/, '$1.$2');
  value = value.replace(/(\d{3})(\d)/, '$1-$2');

  return value;
}

export function formatCNPJ(value: string) {
  if (!value) {
    return '';
  }

  value = value.replace(/\D/g, '');

  if (value.length > 14) {
    value = value.slice(0, -1);
  }

  value = value.replace(/(\d{2})(\d)/, '$1.$2');
  value = value.replace(/(\d{3})(\d)/, '$1.$2');
  value = value.replace(/(\d{3})(\d)/, '$1/$2');
  value = value.replace(/(\d{4})(\d)/, '$1-$2');

  return value;
}

export function formatCEP(value: string) {
  if (!value) {
    return '';
  }

  value = value.replace(/\D/g, '');

  if (value.length > 8) {
    value = value.slice(0, -1);
  }

  value = value.replace(/(\d{5})(\d)/, '$1-$2');
  return value;
}

export function formatPhone(value: string) {
  if (!value) {
    return '';
  }

  value = value.replace(/\D/g, '');

  if (value.length > 11) {
    value = value.slice(0, -1);
  }

  value = value.replace(/(\d{2})(\d)/, '($1)$2');
  value = value.replace(/(\d{5})(\d)/, '$1-$2');
  return value;
}

export function clearMask(value: string) {
  if (!value) {
    return;
  }

  return value.replace(/\D/g, '');
}

import {
  Button,
  Container,
  Flex,
  Grid,
  Group,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft, IconClock, IconX } from '@tabler/icons';
import { useEffect, useState } from 'react';

import { TimeInput } from '@mantine/dates';
import { useParams } from 'react-router-dom';
import { CustomLoader } from '../../components/custom-loader';
import { rootGoBack, rootNavigate } from '../../components/custom-router';
import { Page } from '../../components/page';
import { showWorkScale, updateWorkScaleRequest } from '../../data/work-scale';
import { dayjsPlugins } from '../../providers/dayjs-plugins';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { AppRoutePaths } from '../../utils/enums/routes';

type Days = {
  0: string[];
  1: string[];
  2: string[];
  3: string[];
  4: string[];
  5: string[];
  6: string[];
};

type EditWorkScaleForm = {
  name: string;
  days: Days;
};

export function WorkScaleEdit() {
  const { id } = useParams();
  const [pageLoading, setPageLoading] = useState(false);

  const form = useForm<EditWorkScaleForm>({
    initialValues: {
      name: '',
      days: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
      },
    },
  });

  async function handleSubmit({ ...values }: typeof form.values) {
    try {
      setPageLoading(true);
      await updateWorkScaleRequest(Number(id), values);
      setPageLoading(false);
      successNotification({
        title: 'Escala atualizada.',
        message: 'tudo certo.',
      });
      form.reset();
      rootNavigate(AppRoutePaths.WORK_SCALE_LIST);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao atualizar escala.',
        message: 'tente novamente.',
      });
    }
  }

  async function getScaleInfo() {
    try {
      setPageLoading(true);
      const response = await showWorkScale(Number(id));
      setPageLoading(false);
      form.setValues({
        name: response.name,
        days: response.days,
      });
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar usuário.',
        message: 'tente novamente.',
      });
    }
  }

  useEffect(() => {
    getScaleInfo();
  }, []);

  return (
    <Page>
      <CustomLoader loading={pageLoading} />
      <Container bg="white" h="100%">
        <Button
          leftIcon={<IconArrowLeft />}
          onClick={rootGoBack}
          mt={16}
          mb={16}
          variant="subtle"
        >
          voltar
        </Button>
        <Title>Editar escala</Title>
        <Text color="gray" mb={16}>
          dados da escala
        </Text>
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Grid gutter="xs" columns={3} m={8} w={700} maw={800}>
            <Grid.Col span={3} mb={'sm'}>
              <TextInput
                required
                withAsterisk
                label="Nome"
                placeholder="nome da escala"
                type="text"
                {...form.getInputProps('name')}
              />
            </Grid.Col>

            <Grid.Col span={1}>
              <Flex direction={'column'} gap={'xl'} justify={'center'} mt={5}>
                <Text fw={'bold'} size={'md'}>
                  Dia
                </Text>
                <Text fw={'bold'} size={'md'}>
                  Domingo
                </Text>
                <Text fw={'bold'} size={'md'}>
                  Segunda-Feira
                </Text>
                <Text fw={'bold'} size={'md'}>
                  Terça-Feira
                </Text>
                <Text fw={'bold'} size={'md'}>
                  Quarta-Feira
                </Text>
                <Text fw={'bold'} size={'md'}>
                  Quinta-Feira
                </Text>
                <Text fw={'bold'} size={'md'}>
                  Sexta-Feira
                </Text>
                <Text fw={'bold'} size={'md'}>
                  Sábado
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction={'column'} gap={'xl'} justify={'center'} mt={5}>
                <Text fw={'bold'} size={'md'}>
                  Horário
                </Text>
                <Text size={'md'}>
                  {form.values.days['0'][0]} - {form.values.days['0'][1]}
                  {form.values.days['0'].length ? (
                    <Button
                      color="red"
                      variant="outline"
                      onClick={() => form.setFieldValue('days.0', [])}
                    >
                      <IconX size={16} />
                    </Button>
                  ) : (
                    ''
                  )}
                </Text>
                <Text size={'md'}>
                  {form.values.days['1'][0]} - {form.values.days['1'][1]}
                  {form.values.days['1'].length ? (
                    <Button
                      color="red"
                      variant="subtle"
                      size="xs"
                      compact
                      onClick={() => form.setFieldValue('days.1', [])}
                    >
                      <IconX size={16} />
                    </Button>
                  ) : (
                    ''
                  )}
                </Text>
                <Text size={'md'}>
                  {form.values.days['2'][0]} - {form.values.days['2'][1]}
                  {form.values.days['2'].length ? (
                    <Button
                      color="red"
                      variant="subtle"
                      size="xs"
                      compact
                      onClick={() => form.setFieldValue('days.2', [])}
                    >
                      <IconX size={16} />
                    </Button>
                  ) : (
                    ''
                  )}
                </Text>
                <Text size={'md'}>
                  {form.values.days['3'][0]} - {form.values.days['3'][1]}
                  {form.values.days['3'].length ? (
                    <Button
                      color="red"
                      variant="subtle"
                      size="xs"
                      compact
                      onClick={() => form.setFieldValue('days.3', [])}
                    >
                      <IconX size={16} />
                    </Button>
                  ) : (
                    ''
                  )}
                </Text>
                <Text size={'md'}>
                  {form.values.days['4'][0]} - {form.values.days['4'][1]}
                  {form.values.days['4'].length ? (
                    <Button
                      color="red"
                      variant="subtle"
                      size="xs"
                      compact
                      onClick={() => form.setFieldValue('days.4', [])}
                    >
                      <IconX size={16} />
                    </Button>
                  ) : (
                    ''
                  )}
                </Text>
                <Text size={'md'}>
                  {form.values.days['5'][0]} - {form.values.days['5'][1]}
                  {form.values.days['5'].length ? (
                    <Button
                      color="red"
                      variant="subtle"
                      size="xs"
                      compact
                      onClick={() => form.setFieldValue('days.5', [])}
                    >
                      <IconX size={16} />
                    </Button>
                  ) : (
                    ''
                  )}
                </Text>
                <Text size={'md'}>
                  {form.values.days['6'][0]} - {form.values.days['6'][1]}
                  {form.values.days['6'].length ? (
                    <Button
                      color="red"
                      variant="subtle"
                      size="xs"
                      compact
                      onClick={() => form.setFieldValue('days.6', [])}
                    >
                      <IconX size={16} />
                    </Button>
                  ) : (
                    ''
                  )}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction={'column'} gap={'sm'} mt={48}>
                <Flex gap={'sm'}>
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.0.0',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['0'].shift();
                    }}
                  />
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.0.1',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['0'].pop();
                    }}
                  />
                </Flex>
                <Flex gap={'sm'}>
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.1.0',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['1'].shift();
                    }}
                  />
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.1.1',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['1'].pop();
                    }}
                  />
                </Flex>
                <Flex gap={'sm'}>
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.2.0',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['2'].shift();
                    }}
                  />
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.2.1',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['2'].pop();
                    }}
                  />
                </Flex>
                <Flex gap={'sm'}>
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.3.0',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['3'].shift();
                    }}
                  />
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.3.1',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['3'].pop();
                    }}
                  />
                </Flex>
                <Flex gap={'sm'}>
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.4.0',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['4'].shift();
                    }}
                  />
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.4.1',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['4'].pop();
                    }}
                  />
                </Flex>
                <Flex gap={'sm'}>
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.5.0',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['5'].shift();
                    }}
                  />
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.5.1',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['5'].pop();
                    }}
                  />
                </Flex>
                <Flex gap={'sm'}>
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.6.0',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['6'].shift();
                    }}
                  />
                  <TimeInput
                    icon={<IconClock size={16} />}
                    clearable
                    onChange={(value) => {
                      value
                        ? form.setFieldValue(
                            'days.6.1',
                            dayjsPlugins(value).format('HH:mm'),
                          )
                        : form.values.days['6'].pop();
                    }}
                  />
                </Flex>
              </Flex>
            </Grid.Col>
          </Grid>
          <Group mt={16} mb={16} position="right">
            <Button type="submit">Salvar</Button>
          </Group>
        </form>
      </Container>
    </Page>
  );
}

import { Button, Drawer, Group, MantineProvider, Text } from '@mantine/core';
import { AppShell, Header } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { IconDoorExit, IconMenu } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { Sidebar } from './components/sidebar';

import useAuthContext from './hooks/useAuthContext';
import { UserType } from './models/user';
import { Router } from './routes';

function App() {
  const { user, logout } = useAuthContext();
  const [drawerOpened, setDrawerOpened] = useState(false);

  useEffect(() => {
    if (!user) {
      logout();
    }
  }, [user]);

  return (
    <>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          fontFamily: 'Roboto, sans-serif',
          headings: {
            fontFamily: 'Roboto, sans-serif',
          },
          colorScheme: 'light',
          colors: {
            ltpBlue: [
              '#EAF0FB',
              '#C4D5F3',
              '#9EBAEB',
              '#789FE3',
              '#5285DB',
              '#2C6AD3',
              '#2355A9',
              '#1A3F7F',
              '#112A55',
              '#09152A',
            ],
          },
        }}
      >
        <AppShell
          navbarOffsetBreakpoint="sm"
          asideOffsetBreakpoint="sm"
          styles={() => ({
            main: {
              backgroundColor: '#FBFBFB',
            },
          })}
          header={
            <Header hidden={!user} height={{ base: 50, md: 50 }} p={0}>
              <Group
                style={{ height: '100%' }}
                px={20}
                position={user?.user.type === UserType.USER ? 'right' : 'apart'}
              >
                {user && (
                  <>
                    {user.user.type !== UserType.USER && (
                      <Button
                        onClick={() => setDrawerOpened(true)}
                        variant="subtle"
                        color="indigo"
                      >
                        <IconMenu size={16} />
                      </Button>
                    )}
                    <Button
                      color="indigo"
                      leftIcon={<IconDoorExit size={16} />}
                      onClick={() => logout()}
                      variant="light"
                    >
                      Sair
                    </Button>
                  </>
                )}
              </Group>
            </Header>
          }
        >
          <NotificationsProvider position="top-right">
            <Drawer
              opened={drawerOpened}
              onClose={() => setDrawerOpened(false)}
              styles={{ header: { padding: 8 } }}
              title={<Text fw="bold">Menu</Text>}
            >
              <Sidebar setOpened={setDrawerOpened} />
            </Drawer>
            <Router />
          </NotificationsProvider>
        </AppShell>
      </MantineProvider>
    </>
  );
}

export default App;

import axios from 'axios';
import { AuthUser } from '../hooks/useUser';
import { LocalStorageKeys } from '../utils/enums/local-storage';
import { errorNotification } from './mantine-notifications';
import dayjs from 'dayjs';

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(async (config) => {
  config.headers.timezone = dayjs.tz.guess();

  return config;
});

api.interceptors.request.use(async (config) => {
  const authToken: AuthUser = JSON.parse(
    localStorage.getItem(LocalStorageKeys.LOGGED_USER) ?? '{}',
  );

  if (authToken && config.headers) {
    config.headers.Authorization = `Bearer ${authToken.authToken}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error.response ? error.response.data.message : null;
    if (status === 'invalid signature' || status === 'jwt expired') {
      localStorage.removeItem(LocalStorageKeys.LOGGED_USER);
      window.location.reload();
      errorNotification({
        title: 'Erro de autenticação!',
        message: 'faça o login novamente',
      });
    } else {
      throw error;
    }
  },
);

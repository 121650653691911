import { AxiosResponse } from 'axios';
import { CreateUserData, UpdateUserData } from '../@types/user';
import { User, UserRole } from '../models/user';
import { api } from '../providers/base-api';
import { PaginatedResponse } from '../utils/types/data/pagination';
import { GetUsersPaginatedRequestQuery } from '../utils/types/data/services/users';

export type GetUsersPaginatedQuery = {
  username?: string;
  role?: UserRole;
};

export async function getUsersRequest(
  query?: GetUsersPaginatedQuery,
): Promise<User[]> {
  const response = await api.get<User[]>('/users', {
    params: query,
  });

  return response.data;
}

export async function showUserRequest(id: number): Promise<User> {
  const response = await api.get<User>(`/users/${id}`);

  return response.data;
}

export async function createUserRequest(data: CreateUserData): Promise<User> {
  const response = await api.post<User>('/users', data);

  return response.data;
}

export async function removeUserRequest(userId: number): Promise<void> {
  const response = await api.delete(`/users/${userId}`);

  return response.data;
}

export async function updateUserRequest(
  id: number,
  data: UpdateUserData,
): Promise<User> {
  const response = await api.put<User>(`/users/${id}`, data);

  return response.data;
}

export async function geteUsersPaginatedRequest(
  query: GetUsersPaginatedRequestQuery,
): Promise<AxiosResponse<PaginatedResponse<User>>> {
  const response = await api.get<PaginatedResponse<User>>(`/users/paginated`, {
    params: query,
  });

  return response;
}

import { createContext } from 'react';
import { useCashFlow } from '../hooks/useCashFlow';

interface CashFlowContext {
  balance: string;
  getBalance: () => void;
}

export const CashFlowContext = createContext<CashFlowContext>({
  balance: '',
  getBalance: () => {},
});

const CashFlowProvider = ({ children }: { children: React.ReactNode }) => {
  const { cashFlow, getBalance } = useCashFlow();

  return (
    <CashFlowContext.Provider value={{ balance: cashFlow.balance, getBalance }}>
      {children}
    </CashFlowContext.Provider>
  );
};

export default CashFlowProvider;

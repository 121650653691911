import {
  Badge,
  Button,
  Checkbox,
  Flex,
  Grid,
  Group,
  Menu,
  Modal,
  Select,
  Text,
  TextInput,
} from '@mantine/core';
import { RangeCalendar } from '@mantine/dates';
import { useForm } from '@mantine/form';
import {
  IconCalendarDue,
  IconCirclePlus,
  IconClipboardData,
  IconDotsVertical,
  IconEdit,
  IconFingerprint,
  IconTrash,
  IconUserCheck,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useRef, useState } from 'react';
import { CustomLoader } from '../../components/custom-loader';
import { rootNavigate } from '../../components/custom-router';
import { Page } from '../../components/page';
import {
  createBiometric,
  createBiometricService,
  findBiometricByUser,
} from '../../data/biometric';
import { getCompaniesMinRequest } from '../../data/company';
import { updateEmployeeRequest } from '../../data/employees';
import { useGenerateUserMirror } from '../../data/hooks/daily-work';
import { useGetUsersPaginated, useRestoreUser } from '../../data/hooks/user';
import { useGetWorkScaleList } from '../../data/hooks/work-scale';
import { removeUserRequest } from '../../data/user';
import { useAuth } from '../../hooks/useAuth';
import { Biometric } from '../../models/biometric';
import { CompanyMin } from '../../models/company';
import { User, UserType } from '../../models/user';
import { formatLocale } from '../../providers/dayjs-plugins';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { userRoleHumanized, userRoleList } from '../../utils/constants/user';
import { AppRoutePaths } from '../../utils/enums/routes';

type PageModalState =
  | 'user-mirror'
  | 'confirm-mirror'
  | 'biometric'
  | 'work-scale'
  | null;

function downloadFile(response: Blob, fileName: string) {
  const url = URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
}

const CALENDAR_INITIAL_STATE: [Date | null, Date | null] = [null, null];

export function UserList() {
  const { user } = useAuth();
  const [pageLoading, setPageLoading] = useState(false);
  const [pageModal, setPageModal] = useState<PageModalState>(null);
  const [selectedDate, setSelectedDate] = useState<[Date | null, Date | null]>(
    CALENDAR_INITIAL_STATE,
  );
  const [selectedUser, setSelectedUser] = useState<User[]>([]);
  const [selectedUserId, setSelectUserId] = useState<number>();
  const [userBiometric, setUserBiometric] = useState<Biometric>();
  const [companyList, setCompanyList] = useState<CompanyMin[]>([]);
  const { fetch: restoreUserFetcher, loading: restoreUserLoader } =
    useRestoreUser();
  const {
    fetch: getUsersPaginatedFetcher,
    loading: getUsersPaginatedLoader,
    response: getUsersPaginatedData,
  } = useGetUsersPaginated();
  const { fetch: generateMirrorFetcher, loading: generateMirrorLoader } =
    useGenerateUserMirror();
  const page = useRef(1);
  const pageLimit = useRef(10);

  const formFilter = useForm({
    initialValues: {
      username: '',
      role: '',
      company: '',
      inactive: false,
      workScale: '',
    },
  });

  const formAssignWorkScale = useForm({
    initialValues: {
      workScale: '',
    },
  });

  const {
    fetch: getWorkScaleFetcher,
    response: getWorkScaleData,
    loading: getWorkScaleLoading,
  } = useGetWorkScaleList();

  function handleCloseModal() {
    setUserBiometric(undefined);
    setSelectUserId(undefined);
    setPageModal(null);
  }

  function handleClearFilter() {
    formFilter.reset();
    formFilter.setFieldValue('inactive', false);
    getUsers(true);
  }

  function handleSelectUserMirror() {
    if (!selectedUser.length) {
      errorNotification({
        title: 'Necessário selecionar usuários.',
        message: 'selecione da listagem',
      });

      return;
    }

    if (selectedUser.length > 15 && !formFilter.values.company) {
      errorNotification({
        title: 'Limite de usuário selecionados é de 15 por vez.',
        message: 'selecione um numero menor ou filtre por uma empresa',
      });

      return;
    }

    setPageModal('user-mirror');
  }

  function handlePageLimitChange(limitValue: number) {
    pageLimit.current = limitValue;
    getUsers();
  }

  function handlePageChange(limitValue: number) {
    page.current = limitValue;
    getUsers();
  }

  function handleOpenModal(userId: number, modalName: PageModalState) {
    setSelectUserId(userId);
    setPageModal(modalName);
  }

  async function handleCloseMirrorModal() {
    setPageModal(null);
    setSelectedDate(CALENDAR_INITIAL_STATE);
  }

  async function handleUserMirrorExport() {
    if (selectedDate.at(0)?.getMonth() !== selectedDate.at(1)?.getMonth()) {
      errorNotification({
        title: 'Só é possivel exportar dados para 1 mês.',
        message: 'selecione um intervalo menor',
      });

      return;
    }

    if (selectedDate && selectedUser) {
      generateMirrorFetcher({
        params: {
          date: selectedDate.map((item) => item?.toISOString()).join(','),
          employees: selectedUser.map((userItem) => userItem.id).join(','),
        },
        onSuccess: (data) => {
          downloadFile(
            data,
            `espelho-ponto-${String(new Date(Date.now()).valueOf()).slice(
              7,
              11,
            )}.pdf`,
          );
          setPageModal('confirm-mirror');
          setSelectedDate(CALENDAR_INITIAL_STATE);
          setSelectedUser([]);
          formFilter.reset();
        },
      });
    }
  }

  async function getUsers(reset = false) {
    const validatedValues: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedValues[key] = val;
      }
    });

    await getUsersPaginatedFetcher({
      query: {
        page: page.current,
        limit: pageLimit.current,
        ...(reset ? { inactive: false } : validatedValues),
      },
    });
  }

  async function handleRemoveUser(userId: number) {
    try {
      setPageLoading(true);
      await removeUserRequest(userId);
      setPageLoading(false);
      successNotification({
        title: 'Usuário removido!',
        message: 'tudo certo.',
      });
      getUsers();
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao remover usuário.',
        message: 'tente novamente',
      });
    }
  }

  async function handleRestoreUser(userId: number) {
    restoreUserFetcher({
      param: {
        id: userId,
      },
      onSuccess: () => {
        successNotification({
          title: 'Usuário restaurado!',
          message: 'tudo certo',
        });
        getUsers();
      },
    });
  }

  async function handleCreateBiometric() {
    await createBiometricService()
      .then(async (resp) => {
        if (resp.data.success && selectedUserId) {
          await createBiometric({
            user: selectedUserId,
            template: resp.data.template,
          });
          successNotification({
            title: 'Biometria cadastrada com sucesso!',
            message: 'tudo certo',
          });
          handleCloseModal();
        }
      })
      .catch(() => {
        errorNotification({
          title: 'Erro ao criar biometria.',
          message: 'tente novamente.',
        });
      });
  }

  async function handleOpenBiometricModal(userId: number) {
    setSelectUserId(userId);
    setPageModal('biometric');
    await findBiometricByUser(userId).then((resp) => setUserBiometric(resp));
  }

  async function getCompanies() {
    try {
      setPageLoading(true);
      const response = await getCompaniesMinRequest();
      setCompanyList(response);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar empresas.',
        message: 'tente novamente',
      });
    }
  }

  async function getWorkScales() {
    await getWorkScaleFetcher({});
  }

  async function handleAssignWorkScale({
    ...values
  }: typeof formAssignWorkScale.values) {
    if (selectedUserId) {
      try {
        setPageLoading(true);
        await updateEmployeeRequest(selectedUserId, {
          workScale: Number(values.workScale),
        });
        setPageLoading(false);
        successNotification({
          title: 'Escala atribuida com sucesso!',
          message: 'tudo certo',
        });
        handleCloseModal();
        formAssignWorkScale.reset();
      } catch (error) {
        setPageLoading(false);
        errorNotification({
          title: 'Erro ao atribuir escala ao usuário.',
          message: 'tente novamente.',
        });
      }
    }
  }

  useEffect(() => {
    getCompanies();
    getWorkScales();
    getUsers();
  }, []);

  return (
    <Page>
      <CustomLoader
        loading={
          restoreUserLoader ||
          getUsersPaginatedLoader ||
          generateMirrorLoader ||
          getWorkScaleLoading
        }
      />
      <Flex m={16} direction="column">
        <Flex align="center" justify="end" mb={8}>
          <Button
            variant="filled"
            disabled={!selectedUser.length}
            mr={8}
            leftIcon={<IconClipboardData />}
            onClick={handleSelectUserMirror}
          >
            Espelho
          </Button>
          <Button
            mr={8}
            color="ltpBlue.9"
            leftIcon={<IconCirclePlus />}
            onClick={() => rootNavigate(AppRoutePaths.USERS_CREATE)}
          >
            Novo
          </Button>
        </Flex>
        <Flex wrap="wrap" justify="start" mb={8}>
          <form onSubmit={formFilter.onSubmit(() => getUsers())}>
            <Flex align="center">
              <TextInput
                label="Usuário"
                placeholder="nome do usuário"
                type="text"
                name="username"
                mb={16}
                mr={8}
                {...formFilter.getInputProps('username')}
              />
              <Select
                clearable
                name="role"
                label="Tipo de usuário"
                placeholder="selecione o tipo e usuário"
                data={userRoleList}
                {...formFilter.getInputProps('role')}
                mb={16}
                mr={8}
              />
              <Select
                searchable
                clearable
                label="Empresa"
                placeholder="selecione a empresa"
                data={companyList?.map((item) => ({
                  label: item.fantasyName,
                  value: String(item.id),
                }))}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('company')}
              />
              <Select
                clearable
                label="Escala"
                name="workScale"
                placeholder="selecione a escala"
                data={
                  getWorkScaleData?.map((item) => ({
                    label: item.name,
                    value: String(item.id),
                  })) ?? []
                }
                mb={16}
                mr={8}
                {...formFilter.getInputProps('workScale')}
              />
              <Button mb={-10} type="submit">
                Filtrar
              </Button>
              <Button
                mb={-10}
                ml={10}
                onClick={handleClearFilter}
                color="ltpBlue.9"
                variant="outline"
              >
                Limpar
              </Button>
              <Checkbox
                label="Inativos"
                name="inactive"
                color="blue"
                ml={8}
                mb={-5}
                checked={formFilter.values.inactive}
                {...formFilter.getInputProps('inactive')}
              />
            </Flex>
          </form>
        </Flex>
        <DataTable
          recordsPerPage={getUsersPaginatedData?.meta.itemsPerPage ?? 5}
          selectedRecords={selectedUser}
          onSelectedRecordsChange={(selectedUser) =>
            setSelectedUser(selectedUser)
          }
          recordsPerPageOptions={[10, 50, 100, 500]}
          onRecordsPerPageChange={(recordRange) =>
            handlePageLimitChange(recordRange)
          }
          onPageChange={handlePageChange}
          page={page.current}
          totalRecords={getUsersPaginatedData?.meta.totalItems}
          fetching={pageLoading}
          height="62vh"
          noRecordsText="Sem usuários"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          records={getUsersPaginatedData?.items}
          columns={[
            {
              accessor: 'username',
              title: 'Nome',
            },
            {
              accessor: 'role',
              title: 'Tipo',
              render: ({ role }) => <Badge>{userRoleHumanized[role]}</Badge>,
            },
            {
              accessor: 'accessCode',
              title: 'Código de acesso',
            },
            {
              accessor: 'deletedAt',
              title: 'Status',
              render: ({ deletedAt }) => (
                <Badge color={deletedAt ? 'red' : 'blue'}>
                  {deletedAt ? 'inativo' : 'ativo'}
                </Badge>
              ),
            },
            {
              accessor: 'companyTaker',
              title: 'Empresa',
            },
            {
              accessor: 'workScale',
              title: 'Escala',
            },
            {
              accessor: 'createdAt',
              title: 'Criado em',
              render: ({ createdAt }) => formatLocale(createdAt, 'DD/MM/YYYY'),
            },
            {
              accessor: '',
              title: '',
              render: ({ id, deletedAt }) => (
                <Menu>
                  <Menu.Target>
                    <Button color="blue" variant="subtle" w={40} p={0}>
                      <IconDotsVertical />
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown style={{ position: 'absolute' }}>
                    <Menu.Item
                      onClick={() => {
                        handleOpenModal(id, 'work-scale');
                      }}
                      icon={<IconCalendarDue size={14} />}
                      disabled={user?.user.type !== UserType.MASTER}
                    >
                      Escala
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        handleOpenBiometricModal(id);
                      }}
                      icon={<IconFingerprint size={14} />}
                      disabled={user?.user.type !== UserType.MASTER}
                    >
                      Biometria
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => rootNavigate(`users/edit/${id}`)}
                      icon={<IconEdit size={14} />}
                    >
                      Editar
                    </Menu.Item>
                    <Menu.Item
                      disabled={deletedAt !== null}
                      onClick={() => handleRemoveUser(id)}
                      icon={<IconTrash size={14} />}
                    >
                      Remover
                    </Menu.Item>
                    <Menu.Item
                      disabled={deletedAt === null}
                      onClick={() => handleRestoreUser(id)}
                      icon={<IconUserCheck size={14} />}
                    >
                      Restaurar
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              ),
            },
          ]}
        />
      </Flex>
      <Modal
        title="Exportar Espelho de Ponto:"
        opened={pageModal === 'user-mirror'}
        onClose={handleCloseMirrorModal}
        closeOnClickOutside={false}
        closeOnEscape={false}
        size="70%"
      >
        <Grid columns={5} mb={18}>
          <Grid.Col span={2}>
            <Group position="center">
              <RangeCalendar
                locale="pt-br"
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
              />
              <Group position="left">
                <Button
                  onClick={() => setSelectedDate(CALENDAR_INITIAL_STATE)}
                  variant="subtle"
                >
                  limpar
                </Button>
              </Group>
            </Group>
          </Grid.Col>
          <Grid.Col span={3}>
            <DataTable
              fetching={pageLoading}
              minHeight={200}
              noRecordsText="Sem funcionários"
              withBorder
              borderRadius="sm"
              striped
              highlightOnHover
              records={selectedUser}
              columns={[
                {
                  accessor: 'username',
                  title: 'Nome',
                },
                {
                  accessor: 'role',
                  title: 'Tipo',
                  render: ({ role }) => (
                    <Badge>{userRoleHumanized[role]}</Badge>
                  ),
                },
                {
                  accessor: 'accessCode',
                  title: 'Código de acesso',
                },
              ]}
            />
          </Grid.Col>
        </Grid>
        <Group position="right">
          <Button
            disabled={!selectedDate}
            onClick={() => handleUserMirrorExport()}
          >
            Gerar
          </Button>
        </Group>
        <Text fw="bold">Instruções:</Text>
        <Text>
          1- selecione a data e logo após verifique os usuários que deseja gerar
          o documento.
        </Text>
        <Text>2- logo aṕos, o documento será baixado.</Text>
      </Modal>
      <Modal
        title="Exportar Recibo."
        opened={pageModal === 'confirm-mirror'}
        onClose={() => setPageModal(null)}
        closeOnClickOutside={false}
        withCloseButton={false}
        closeOnEscape={false}
      >
        <Group>
          <Text fw="bold">Tudo certo!</Text>
          <Text>O documento vai aparecer em instantes no seus Downloads.</Text>
        </Group>
        <Group position="right">
          <Button onClick={() => setPageModal(null)}>OK</Button>
        </Group>
      </Modal>
      <Modal
        title="Biometria"
        opened={pageModal === 'biometric'}
        onClose={() => {
          handleCloseModal();
        }}
        size="30%"
      >
        <Flex direction={'column'}>
          {!userBiometric?.template && (
            <Text fw={'bold'} mb={'xs'}>
              Sem biometria cadastrada para este usuário
            </Text>
          )}

          {userBiometric?.template && (
            <Text fw={'bold'} mb={'xs'}>
              Biometria já cadastrada!
            </Text>
          )}

          <Button onClick={() => handleCreateBiometric()}>Novo</Button>
        </Flex>
      </Modal>
      <Modal
        title="Atribuir escala"
        opened={pageModal === 'work-scale'}
        onClose={() => {
          handleCloseModal();
        }}
        size="30%"
      >
        <Flex direction={'column'}>
          <form
            onSubmit={formAssignWorkScale.onSubmit((values) =>
              handleAssignWorkScale(values),
            )}
          >
            <Select
              clearable
              name="workScale"
              placeholder="selecione a escala"
              data={
                getWorkScaleData?.map((item) => ({
                  label: item.name,
                  value: String(item.id),
                })) ?? []
              }
              mb={16}
              mr={8}
              {...formAssignWorkScale.getInputProps('workScale')}
            />
            <Group mt={16} mb={16} position="right">
              <Button type="submit">Salvar</Button>
            </Group>
          </form>
        </Flex>
      </Modal>
    </Page>
  );
}

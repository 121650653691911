import {
  Button,
  Container,
  Grid,
  Group,
  Modal,
  Select,
  Switch,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft, IconCheck, IconX } from '@tabler/icons';
import { useEffect, useState } from 'react';

import { CustomLoader } from '../../components/custom-loader';
import { rootGoBack, rootNavigate } from '../../components/custom-router';
import { Page } from '../../components/page';
import { createEmployeeRequest } from '../../data/employees';
import { createUserRequest } from '../../data/user';
import { EmployeePeriod } from '../../models/employees';
import { User, UserRole, UserType } from '../../models/user';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { employeePeriodList } from '../../utils/constants/employee';
import { userRoleList } from '../../utils/constants/user';
import { AppRoutePaths } from '../../utils/enums/routes';
import { formatBRL, realToUSCash } from '../../utils/helpers';
import { formatCEP, formatCPF, formatRG } from '../../utils/masks';
import { getCompaniesMinRequest } from '../../data/company';
import { CompanyMin } from '../../models/company';

export function UserCreate() {
  const [pageLoading, setPageLoading] = useState(false);
  const [userInfoModal, setUserInfoModal] = useState(false);
  const [companyList, setCompanyList] = useState<CompanyMin[]>([]);
  const companyOptions = companyList?.map((item) => ({
    label: item.fantasyName,
    value: String(item.id),
  }));
  const [newUser, setNewUser] = useState<User>();
  const form = useForm({
    initialValues: {
      username: '',
      role: '',
      type: 'user',
      employee: {
        paymentPrice: '',
        transportPrice: '',
        needTransport: false,
        documentRG: '',
        documentCPF: '',
        documentPIS: '',
        period: '',
        addressZipCode: '',
        addressStreet: '',
        addressNumber: '',
        addressCity: '',
        note: '',
        company: null,
      },
    },
  });

  async function handleSubmit({ employee, ...values }: typeof form.values) {
    try {
      setPageLoading(true);
      const response = await createUserRequest({
        ...values,
        role: values.role as UserRole,
        type: values.type as UserType,
      });

      await createEmployeeRequest(response.id, {
        ...employee,
        paymentPrice: employee.paymentPrice
          ? realToUSCash(employee.paymentPrice)
          : undefined,
        transportPrice: employee.transportPrice
          ? realToUSCash(employee.transportPrice)
          : undefined,
        period: employee.period as EmployeePeriod.DAY,
        company: employee.company ? Number(employee.company) : undefined,
      });
      setPageLoading(false);
      setNewUser(response);
      successNotification({
        title: 'Usuário cadastrado.',
        message: 'tudo certo.',
      });
      setUserInfoModal(true);
      form.reset();
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao cadastrar usuário.',
        message: 'tente novamente.',
      });
    }
  }

  async function getCompanies() {
    const response = await getCompaniesMinRequest();
    setCompanyList(response);
  }

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <Page>
      <CustomLoader loading={pageLoading} />
      <Container bg="white" h="100%">
        <Button
          leftIcon={<IconArrowLeft />}
          onClick={rootGoBack}
          mt={16}
          mb={16}
          variant="subtle"
        >
          voltar
        </Button>
        <Title>Cadastro de usuário</Title>
        <Text color="gray" mb={16}>
          dados do novo usuário
        </Text>
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Grid columns={2} maw={500}>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Nome"
                placeholder="nome completo"
                type="text"
                {...form.getInputProps('username')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Select
                required
                withAsterisk
                name="role"
                label="Tipo de usuário"
                placeholder="selecione o tipo e usuário"
                data={userRoleList}
                {...form.getInputProps('role')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Select
                required
                withAsterisk
                name="role"
                label="Periodo de trabalho"
                placeholder="selecione o periodo"
                data={employeePeriodList}
                {...form.getInputProps('employee.period')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Select
                searchable
                name="company"
                label="Empresa"
                placeholder="selecione a empresa"
                data={companyOptions ?? []}
                {...form.getInputProps('employee.company')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Text color="blue" fw="bold" size={14}>
                Custos
              </Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Valor da diária"
                placeholder="digite o valor"
                type="text"
                value={form.values.employee.paymentPrice}
                onChange={(e) =>
                  form.setFieldValue(
                    'employee.paymentPrice',
                    formatBRL(e.target.value),
                  )
                }
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Switch
                mt={32}
                checked={form.values.employee.needTransport}
                color="teal"
                size="sm"
                label="Recebe vale-transporte?"
                thumbIcon={
                  form.values.employee.needTransport ? (
                    <IconCheck size="0.8rem" color="teal" stroke={3} />
                  ) : (
                    <IconX size="0.8rem" color="red" stroke={3} />
                  )
                }
                {...form.getInputProps('employee.needTransport')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                disabled={!form.values.employee.needTransport}
                label="Valor da condução"
                placeholder="digite o valor"
                type="text"
                value={form.values.employee.transportPrice}
                onChange={(e) =>
                  form.setFieldValue(
                    'employee.transportPrice',
                    formatBRL(e.target.value),
                  )
                }
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Text color="blue" fw="bold" size={14}>
                Documentos
              </Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                label="RG"
                placeholder="digite o documento"
                type="text"
                value={form.values.employee.documentRG}
                onChange={(e) =>
                  form.setFieldValue(
                    'employee.documentRG',
                    formatRG(e.target.value),
                  )
                }
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                label="CPF"
                placeholder="digite o documento"
                type="text"
                value={form.values.employee.documentCPF}
                onChange={(e) =>
                  form.setFieldValue(
                    'employee.documentCPF',
                    formatCPF(e.target.value),
                  )
                }
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                label="PIS"
                placeholder="digite o documento"
                type="text"
                {...form.getInputProps('employee.documentPIS')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Text color="blue" fw="bold" size={14}>
                Complementares
              </Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                label="CEP"
                placeholder="ex: 01105-000"
                type="text"
                value={form.values.employee.addressZipCode}
                onChange={(e) =>
                  form.setFieldValue(
                    'employee.addressZipCode',
                    formatCEP(e.target.value),
                  )
                }
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                label="Número"
                placeholder="ex: 458"
                type="text"
                {...form.getInputProps('employee.addressNumber')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <TextInput
                label="Endereço"
                placeholder="ex: Rua João Teodoro"
                type="text"
                {...form.getInputProps('employee.addressStreet')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Textarea
                label="Observações"
                placeholder="obs sobre o usuário"
                {...form.getInputProps('employee.note')}
              />
            </Grid.Col>
          </Grid>
          <Group mt={16} mb={16} position="right">
            <Button type="submit">Salvar</Button>
          </Group>
        </form>
      </Container>
      <Modal
        title="Dados do novo usuário"
        opened={userInfoModal}
        onClose={() => setUserInfoModal(false)}
      >
        <Group>
          <Text fw="bold">Nome:</Text>
          <Text>{newUser?.username}</Text>
        </Group>
        <Group>
          <Text fw="bold">Código de Acesso:</Text>
          <Text>{newUser?.accessCode}</Text>
        </Group>
        <Group>
          <Text fw="bold">Senha:</Text>
          <Text>{newUser?.password}</Text>
        </Group>
        <Group
          onClick={() => rootNavigate(AppRoutePaths.USERS)}
          mt={16}
          position="right"
        >
          <Button type="button">Ok</Button>
        </Group>
      </Modal>
    </Page>
  );
}

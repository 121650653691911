import { Button, Flex, Menu, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCirclePlus, IconDotsVertical, IconEdit } from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { rootNavigate } from '../../components/custom-router';
import { Page } from '../../components/page';
import { getCompaniesRequest } from '../../data/company';
import { Company } from '../../models/company';
import { errorNotification } from '../../providers/mantine-notifications';
import { AppRoutePaths } from '../../utils/enums/routes';
import { formatCNPJ } from '../../utils/masks';

export function CompanyList() {
  const [pageLoading, setPageLoading] = useState(false);
  const [companylist, setCompanyList] = useState<Company[]>([]);

  const formFilter = useForm({
    initialValues: {
      fantasyName: '',
    },
  });

  function handleClearFilter() {
    formFilter.reset();
    getCompanies(true);
  }

  async function getCompanies(reset = false) {
    const validatedValues: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedValues[key] = val;
      }
    });

    try {
      setPageLoading(true);
      const response = await getCompaniesRequest({
        ...(reset ? {} : validatedValues),
      });
      setCompanyList(response);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar empresas.',
        message: 'tente novamente',
      });
    }
  }

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <Page>
      <Flex m={16} direction="column">
        <Flex align="center" justify="end" mb={8}>
          <Button
            mr={8}
            color="ltpBlue.9"
            leftIcon={<IconCirclePlus />}
            onClick={() => rootNavigate(AppRoutePaths.COMPANIES_CREATE)}
          >
            Novo
          </Button>
        </Flex>
        <Flex wrap="wrap" justify="start" mb={8}>
          <form onSubmit={formFilter.onSubmit(() => getCompanies())}>
            <Flex align="center">
              <TextInput
                label="Nome"
                placeholder="digite o nome"
                mb={16}
                mr={8}
                type="text"
                name="name"
                {...formFilter.getInputProps('fantasyName')}
              />
              <Button mb={-10} type="submit">
                Filtrar
              </Button>
              <Button
                mb={-10}
                ml={10}
                onClick={handleClearFilter}
                color="ltpBlue.9"
                variant="outline"
              >
                Limpar
              </Button>
            </Flex>
          </form>
        </Flex>
        <DataTable
          fetching={pageLoading}
          height="72vh"
          noRecordsText="Sem empresas"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          records={companylist}
          columns={[
            {
              accessor: 'socialReason',
              title: 'Nome',
              width: 300,
            },
            {
              accessor: 'cnpj',
              title: 'CNPJ',
              render: ({ cnpj }) => formatCNPJ(cnpj),
            },
            {
              accessor: 'addressCity',
              title: 'Cidade',
            },
            {
              accessor: '',
              title: 'Endereço',
              render: ({ addressStreet, addressNumber, addressZipCode }) => {
                return (
                  addressStreet +
                  ', ' +
                  `N° ${addressNumber}` +
                  ', ' +
                  addressZipCode
                );
              },
              width: 400,
            },
            {
              accessor: 'company-table-menu',
              title: '',
              render: ({ id }) => (
                <Menu>
                  <Menu.Target>
                    <Button color="blue" variant="subtle" w={40} p={0}>
                      <IconDotsVertical />
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown style={{ position: 'absolute' }}>
                    <Menu.Item
                      onClick={() => rootNavigate(`companies/edit/${id}`)}
                      icon={<IconEdit size={14} />}
                    >
                      Editar
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              ),
            },
          ]}
        />
      </Flex>
    </Page>
  );
}

import { useFetch } from '../../../hooks/useFetch';
import { errorNotification } from '../../../providers/mantine-notifications';
import { translateServerHttpErrors } from '../../../utils/helpers';
import { UseGetFilterUsersProps } from '../../../utils/types/data/hooks/filters';
import { GetFilterUsersResponse } from '../../../utils/types/data/services/filters';
import { getUserFiltersRequest } from '../../services/filters';

export function useGetFilterUsers() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<GetFilterUsersResponse[]>();

  const fetch = ({ onSuccess }: UseGetFilterUsersProps) =>
    fetchData({
      fetcher: () => getUserFiltersRequest(),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao buscar filtros de usuários.',
          message: translateServerHttpErrors(error, 'Tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

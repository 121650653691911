import { User } from './user';

export enum EmployeePeriod {
  DAY = 'day',
  NIGHT = 'night',
}

export type Employee = {
  id: number;
  user: User;
  paymentPrice?: string;
  transportPrice?: string;
  needTransport: boolean;
  documentRG?: string;
  documentCPF?: string;
  documentPIS?: string;
  period: EmployeePeriod;
  addressZipCode?: string;
  addressStreet?: string;
  addressNumber?: string;
  addressCity?: string;
  note?: string;
  company?: number;
};

import {
  Button,
  Flex,
  Group,
  Menu,
  Modal,
  Radio,
  Select,
  Text,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Calendar, TimeInput } from '@mantine/dates';
import {
  IconClock,
  IconDotsVertical,
  IconListDetails,
  IconTrash,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { CustomDateRangePicker } from '../../components/custom-date-range-picker';
import { CustomLoader } from '../../components/custom-loader';
import { Page } from '../../components/page';
import { createBankHourRequest } from '../../data/bank-hours';
import {
  useDeleteBankHour,
  useGetBankHours,
} from '../../data/hooks/bank-hours';
import { getUsersRequest } from '../../data/user';
import { useAuth } from '../../hooks/useAuth';
import { BankHour } from '../../models/bank-hour';
import { User, UserType } from '../../models/user';
import { dayjsPlugins, formatLocale } from '../../providers/dayjs-plugins';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import {
  getMinutesAndHours,
  translateServerHttpErrors,
} from '../../utils/helpers';

type PageModalState = 'create-bank' | 'remove-bank' | null;

type CreateBankHourFormType = {
  date: Date;
  start: Date | undefined;
  end: Date | undefined;
  type: string;
  description?: string;
  user: string;
  amount: number;
};

export function BankHoursList() {
  const { user } = useAuth();
  const [pageModal, setPageModal] = useState<PageModalState>(null);
  const [pageLoading, setPageLoading] = useState(false);
  const page = useRef(1);
  const limit = useRef(10);
  const [userList, setUserList] = useState<User[]>([]);
  const [selectedBankHour, setSelectedBankHour] = useState<BankHour>();

  const {
    loading: getBankHoursLoader,
    fetch: getBankHoursFetcher,
    response,
  } = useGetBankHours();

  const { loading: deleteBankHoursLoader, fetch: deleteBankHoursFetcher } =
    useDeleteBankHour();

  const createBankHourForm = useForm<CreateBankHourFormType>({
    initialValues: {
      date: new Date(),
      start: undefined,
      end: undefined,
      type: '',
      description: '',
      user: '',
      amount: 0,
    },
  });

  const formFilter = useForm({
    initialValues: {
      page: page.current,
      limit: limit.current,
      user: '',
      date: undefined,
    },
  });

  async function getUsers() {
    try {
      setPageLoading(true);
      const response = await getUsersRequest();
      setUserList(response);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar usuários.',
        message: 'tente novamente',
      });
    }
  }

  async function handleRemoveBankHour() {
    if (selectedBankHour) {
      await deleteBankHoursFetcher({
        params: {
          id: selectedBankHour.id,
        },
        onSuccess: () => {
          successNotification({
            title: 'Banco de horas removido.',
            message: 'tudo certo.',
          });
          setPageModal(null);
          getBankHours();
        },
      });
    }
  }

  async function handleSubmit({ ...values }: typeof createBankHourForm.values) {
    if (!values.start || !values.end) {
      errorNotification({
        title: 'Inicio e Fim são obrigatórios.',
        message: 'tente novamente',
      });
      return;
    }

    try {
      setPageLoading(true);
      const diff = dayjsPlugins(values.end).diff(values.start, 'minutes');
      await createBankHourRequest({
        ...values,
        amount: values.type === 'credit' ? diff : diff * -1,
        start: dayjsPlugins(values.date)
          .set('hour', values.start.getHours())
          .set('minute', values.start.getMinutes())
          .set('second', 0)
          .toDate(),
        end: dayjsPlugins(values.date)
          .set('hour', values.end.getHours())
          .set('minute', values.end.getMinutes())
          .set('second', 0)
          .toDate(),
      });
      setPageLoading(false);
      successNotification({
        title: 'Banco de horas cadastrado.',
        message: 'tudo certo.',
      });
      setPageModal(null);
      createBankHourForm.reset();
      getBankHours();
    } catch (error: any) {
      setPageLoading(false);
      errorNotification({
        title: translateServerHttpErrors(
          error,
          'Erro ao registrar banco de horas.',
        ),
        message: 'tente novamente',
      });
    }
  }

  async function getBankHours(isReset = false) {
    const validatedValues: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedValues[key] = val;
      }
    });

    if (isReset) {
      await getBankHoursFetcher({
        params: {
          page: 1,
          limit: 10,
        },
      });
    } else {
      await getBankHoursFetcher({
        params: {
          ...validatedValues,
          page: page.current,
          limit: limit.current,
        },
      });
    }
  }

  async function handlePageChange(newPage: number) {
    page.current = newPage;
    getBankHours();
  }

  async function handlePageLimitChange(newLimit: number) {
    limit.current = newLimit;
    getBankHours();
  }

  useEffect(() => {
    getUsers();
  }, []);

  const rangeTime = useMemo(() => {
    const start = createBankHourForm.values.start;
    const end = createBankHourForm.values.end;

    const { hours, restMinutes } = getMinutesAndHours(
      dayjsPlugins(end).diff(start, 'minutes'),
    );

    return `${hours.toString().padStart(2, '0')}:${restMinutes
      .toString()
      .padStart(2, '0')} `;
  }, [createBankHourForm.values.start, createBankHourForm.values.end]);

  return (
    <Page title="Banco de horas">
      <CustomLoader
        loading={pageLoading || getBankHoursLoader || deleteBankHoursLoader}
      />
      <Flex m={16} direction="column">
        <Flex align="center" justify="end">
          <Button
            disabled={user?.user.type !== UserType.MASTER}
            color="teal.5"
            ml={8}
            rightIcon={<IconListDetails />}
            onClick={() => setPageModal('create-bank')}
          >
            Adicionar
          </Button>
        </Flex>
        <Flex wrap="wrap" justify="start" mb={8}>
          <form onSubmit={formFilter.onSubmit(() => getBankHours())}>
            <Flex align="center">
              <Select
                searchable
                clearable
                label="Usuário"
                placeholder="selecione o usuário"
                data={userList?.map((item) => ({
                  label: item.username,
                  value: String(item.id),
                }))}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('user')}
              />
              <CustomDateRangePicker
                allowSingleDateInRange
                placeholder="selecione um intervalo"
                label="Data"
                maw={170}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('date')}
              />
              <Button mb={-10} type="submit">
                Filtrar
              </Button>
            </Flex>
          </form>
        </Flex>

        <DataTable
          recordsPerPage={response?.meta.itemsPerPage ?? 5}
          recordsPerPageOptions={[10, 50, 100, 500]}
          onRecordsPerPageChange={(recordRange) =>
            handlePageLimitChange(recordRange)
          }
          onPageChange={handlePageChange}
          totalRecords={response?.meta.totalItems}
          page={page.current}
          fetching={getBankHoursLoader}
          records={response?.items}
          noRecordsText="Vazio"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          minHeight={500}
          rowStyle={({ amount }) =>
            amount < 0
              ? {
                  backgroundColor: 'rgba(255, 0, 0, 0.036)',
                  color: 'red',
                }
              : {
                  backgroundColor: 'rgba(0, 255, 0, 0.036)',
                  color: 'green',
                }
          }
          columns={[
            {
              accessor: 'username',
              title: 'Usuário',
            },
            {
              accessor: '',
              title: 'Período',
              render: ({ start, end }) => {
                return (
                  formatLocale(start, 'HH:mm') +
                  ' - ' +
                  formatLocale(end, 'HH:mm')
                );
              },
            },
            {
              accessor: 'date',
              title: 'Data',
              render: ({ date }) => {
                return formatLocale(date, 'DD/MM/YY');
              },
            },
            {
              accessor: 'amount',
              title: 'Saldo',
              render: ({ amount }) => {
                const { hours, restMinutes } = getMinutesAndHours(
                  Math.abs(amount),
                );
                return (
                  <Text fw="bold">
                    {amount > 0 && '+'}
                    {amount < 0 && '-'}
                    {hours.toString().padStart(2, '0')}h
                    {restMinutes.toString().padStart(2, '0')}min
                  </Text>
                );
              },
            },
            {
              accessor: 'description',
              title: 'Observação',
            },
            {
              accessor: 'bank-hour-menu',
              title: '',
              render: (bankHourItem) => (
                <Menu>
                  <Menu.Target>
                    <Button color="blue" variant="subtle" w={40} p={0}>
                      <IconDotsVertical />
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown style={{ position: 'absolute' }}>
                    <Menu.Item
                      icon={<IconTrash size={14} />}
                      onClick={() => {
                        setSelectedBankHour(bankHourItem);
                        setPageModal('remove-bank');
                      }}
                      disabled={user?.user.type !== UserType.MASTER}
                    >
                      Remover
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              ),
            },
          ]}
        />

        <Group mt={8} position="right">
          <Text fw="bold">
            Total: {response?.meta.custom?.formatedTotal ?? '00h00min'}
          </Text>
        </Group>
      </Flex>
      <Modal
        title="Adicionar lançamento"
        opened={pageModal === 'create-bank'}
        onClose={() => {
          createBankHourForm.reset();
          setPageModal(null);
        }}
        closeOnClickOutside={false}
        size={460}
      >
        <form
          onSubmit={createBankHourForm.onSubmit((values) =>
            handleSubmit(values),
          )}
        >
          <Flex
            w="100%"
            direction="column"
            gap={18}
            justify="center"
            align="center"
            mb={12}
          >
            <Select
              searchable
              clearable
              label="Usuário"
              placeholder="selecione o usuário"
              data={userList?.map((item) => ({
                label: item.username,
                value: String(item.id),
              }))}
              {...createBankHourForm.getInputProps('user')}
              w="100%"
              withAsterisk
              required
            />
            <Radio.Group
              name="type"
              withAsterisk
              required
              label="Tipo"
              {...createBankHourForm.getInputProps('type')}
            >
              <Radio value="credit" label="Creditar" required />
              <Radio value="debit" label="Debitar" required />
            </Radio.Group>
            {createBankHourForm.values.type !== '' && (
              <>
                <Calendar
                  locale="pt-br"
                  multiple={false}
                  {...createBankHourForm.getInputProps('date')}
                  size="md"
                />
                <Flex w="100%" justify="space-around" align="center">
                  <TimeInput
                    label="Inicio"
                    withAsterisk
                    required
                    icon={<IconClock size={16} />}
                    clearable
                    {...createBankHourForm.getInputProps('start')}
                  />
                  <TimeInput
                    label="Fim"
                    withAsterisk
                    required
                    icon={<IconClock size={16} />}
                    {...createBankHourForm.getInputProps('end')}
                    clearable
                  />
                </Flex>
                <Text size="xs" color="dimmed">
                  {rangeTime}
                </Text>
                <Textarea
                  w="100%"
                  label="Observação:"
                  {...createBankHourForm.getInputProps('description')}
                />
                <Group position="right">
                  <Button disabled={!createBankHourForm.isValid} type="submit">
                    Adicionar
                  </Button>
                </Group>
              </>
            )}
          </Flex>
        </form>
      </Modal>

      <Modal
        title="Apagar"
        opened={pageModal === 'remove-bank'}
        closeOnEscape={false}
        withCloseButton={false}
        closeOnClickOutside={false}
        size={460}
        onClose={() => {}}
      >
        <Group>
          <Text fw="bold" size="md">
            Deseja realmente apagar este registro?
          </Text>
          <Flex w="100%" gap={16} justify="center" align="center">
            <Button onClick={() => handleRemoveBankHour()} color="green">
              Sim
            </Button>
            <Button onClick={() => setPageModal(null)} color="ltpBlue.9">
              Não
            </Button>
          </Flex>
        </Group>
      </Modal>
    </Page>
  );
}

import {
  Button,
  Container,
  Grid,
  Group,
  Select,
  Switch,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft, IconCheck, IconX } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CustomLoader } from '../../components/custom-loader';
import { rootGoBack, rootNavigate } from '../../components/custom-router';
import { Page } from '../../components/page';
import {
  showEmployeeRequest,
  updateEmployeeRequest,
} from '../../data/employees';
import { showUserRequest, updateUserRequest } from '../../data/user';
import { EmployeePeriod } from '../../models/employees';
import { User, UserRole, UserType } from '../../models/user';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { employeePeriodList } from '../../utils/constants/employee';
import { userRoleList } from '../../utils/constants/user';
import { AppRoutePaths } from '../../utils/enums/routes';
import { formatBRL, realToUSCash } from '../../utils/helpers';
import { formatCEP, formatCPF, formatRG } from '../../utils/masks';
import { CompanyMin } from '../../models/company';
import { getCompaniesMinRequest } from '../../data/company';

export function UserEdit() {
  const { id } = useParams();
  const [pageLoading, setPageLoading] = useState(false);
  const [, setUserInfo] = useState<User>();
  const [companyList, setCompanyList] = useState<CompanyMin[]>([]);
  const companyOptions = companyList?.map((item) => ({
    label: item.fantasyName,
    value: String(item.id),
  }));

  const form = useForm({
    initialValues: {
      username: '',
      role: '',
      type: 'user',
      accessCode: '',
      password: '',
      employee: {
        paymentPrice: '',
        transportPrice: '',
        needTransport: false,
        documentRG: '',
        documentCPF: '',
        documentPIS: '',
        period: '',
        addressZipCode: '',
        addressStreet: '',
        addressNumber: '',
        addressCity: '',
        note: '',
        company: '',
      },
    },
  });

  async function getCompanies() {
    const response = await getCompaniesMinRequest();
    setCompanyList(response);
  }

  async function getUserInfo() {
    try {
      setPageLoading(true);
      const response = await showUserRequest(Number(id));
      const employeeResponse = await showEmployeeRequest(Number(id));
      setUserInfo(response);
      setPageLoading(false);
      form.setValues({
        accessCode: response.accessCode,
        role: response.role,
        username: response.username,
        employee: {
          paymentPrice: formatBRL(employeeResponse.paymentPrice) ?? undefined,
          transportPrice:
            formatBRL(employeeResponse.transportPrice) ?? undefined,
          addressCity: employeeResponse.addressCity ?? '',
          addressNumber: employeeResponse?.addressNumber ?? '',
          addressStreet: employeeResponse.addressStreet ?? '',
          addressZipCode: employeeResponse.addressZipCode ?? '',
          documentCPF: formatCPF(employeeResponse.documentCPF ?? ''),
          documentPIS: employeeResponse.documentPIS ?? '',
          documentRG: formatRG(employeeResponse.documentRG ?? ''),
          needTransport: employeeResponse.needTransport,
          note: employeeResponse.note ?? '',
          period: employeeResponse.period,
          company: String(employeeResponse.company),
        },
      });
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar usuário.',
        message: 'tente novamente.',
      });
    }
  }

  async function handleSubmit({ employee, ...values }: typeof form.values) {
    try {
      setPageLoading(true);
      await updateUserRequest(Number(id), {
        ...values,
        role: values.role as UserRole,
        type: values.type as UserType,
        password:
          form.values.password !== '' ? form.values.password : undefined,
      });

      await updateEmployeeRequest(Number(id), {
        ...employee,
        paymentPrice: employee.paymentPrice
          ? realToUSCash(employee.paymentPrice)
          : undefined,
        transportPrice: employee.transportPrice
          ? realToUSCash(employee.transportPrice)
          : undefined,
        period: employee.period as EmployeePeriod.DAY,
        company: employee.company ? Number(employee.company) : undefined,
      });
      setPageLoading(false);
      successNotification({
        title: 'Usuário atualizado.',
        message: 'tudo certo.',
      });
      rootNavigate(AppRoutePaths.USERS);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao atualizar usuário.',
        message: 'tente novamente.',
      });
    }
  }

  useEffect(() => {
    getCompanies();
    getUserInfo();
  }, []);

  return (
    <Page>
      <CustomLoader loading={pageLoading} />
      <Container bg="white" h="100%">
        <Button
          leftIcon={<IconArrowLeft />}
          onClick={rootGoBack}
          mt={16}
          mb={16}
          variant="subtle"
        >
          voltar
        </Button>
        <Title>Editar usuário</Title>
        <Text color="gray" mb={16}>
          dados do usuário
        </Text>
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Grid columns={2} maw={500}>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Nome"
                placeholder="nome completo"
                type="text"
                {...form.getInputProps('username')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Código de Acesso"
                placeholder="digite para alterar"
                type="text"
                {...form.getInputProps('accessCode')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Select
                required
                withAsterisk
                name="role"
                label="Tipo de usuário"
                placeholder="selecione o tipo e usuário"
                data={userRoleList}
                {...form.getInputProps('role')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Select
                required
                withAsterisk
                name="role"
                label="Periodo de trabalho"
                placeholder="selecione o periodo"
                data={employeePeriodList}
                {...form.getInputProps('employee.period')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Select
                searchable
                name="company"
                label="Empresa"
                placeholder="selecione a empresa"
                data={companyOptions ?? []}
                {...form.getInputProps('employee.company')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                withAsterisk
                label="Senha"
                placeholder="digite uma nova senha"
                type="text"
                {...form.getInputProps('password')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Text color="blue" fw="bold" size={14}>
                Custos
              </Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Valor da diária"
                placeholder="digite o valor"
                type="text"
                value={form.values.employee.paymentPrice}
                onChange={(e) =>
                  form.setFieldValue(
                    'employee.paymentPrice',
                    formatBRL(e.target.value),
                  )
                }
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Switch
                mt={32}
                checked={form.values.employee.needTransport}
                color="teal"
                size="sm"
                label="Recebe vale-transporte?"
                thumbIcon={
                  form.values.employee.needTransport ? (
                    <IconCheck size="0.8rem" color="teal" stroke={3} />
                  ) : (
                    <IconX size="0.8rem" color="red" stroke={3} />
                  )
                }
                {...form.getInputProps('employee.needTransport')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                disabled={!form.values.employee.needTransport}
                label="Valor da condução"
                placeholder="digite o valor"
                type="text"
                value={form.values.employee.transportPrice}
                onChange={(e) =>
                  form.setFieldValue(
                    'employee.transportPrice',
                    formatBRL(e.target.value),
                  )
                }
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Text color="blue" fw="bold" size={14}>
                Documentos
              </Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                label="RG"
                placeholder="digite o documento"
                type="text"
                value={form.values.employee.documentRG}
                onChange={(e) =>
                  form.setFieldValue(
                    'employee.documentRG',
                    formatRG(e.target.value),
                  )
                }
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                label="CPF"
                placeholder="digite o documento"
                type="text"
                value={form.values.employee.documentCPF}
                onChange={(e) =>
                  form.setFieldValue(
                    'employee.documentCPF',
                    formatCPF(e.target.value),
                  )
                }
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                label="PIS"
                placeholder="digite o documento"
                type="text"
                {...form.getInputProps('employee.documentPIS')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Text color="blue" fw="bold" size={14}>
                Complementares
              </Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                label="CEP"
                placeholder="ex: 01105-000"
                type="text"
                value={form.values.employee.addressZipCode}
                onChange={(e) =>
                  form.setFieldValue(
                    'employee.addressZipCode',
                    formatCEP(e.target.value),
                  )
                }
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                label="Número"
                placeholder="ex: 458"
                type="text"
                {...form.getInputProps('employee.addressNumber')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <TextInput
                label="Endereço"
                placeholder="ex: Rua João Teodoro"
                type="text"
                {...form.getInputProps('employee.addressStreet')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Textarea
                label="Observações"
                placeholder="obs sobre o usuário"
                {...form.getInputProps('employee.note')}
              />
            </Grid.Col>
          </Grid>
          <Group mt={16} mb={16} position="right">
            <Button type="submit">Salvar</Button>
          </Group>
        </form>
      </Container>
    </Page>
  );
}

import { EmployeeTransport } from './employee-transport';
import { Employee } from './employees';

export enum UserRole {
  EMPLOYEE = 'employee',
  DAILY_EMPLOYEE = 'daily-employee',
  MONTHLY_EMPLOYEE = 'monthly-employee',
  NONE = 'none',
  MANAGER_VIEWER = 'manager-viewer',
  MANAGER_CREATOR = 'manager-creator',
}

export enum UserType {
  USER = 'user',
  MASTER = 'master',
  MANAGER = 'manager',
}

export type User = {
  id: number;
  username: string;
  role: UserRole;
  type: UserType;
  accessCode: string;
  password: string;
  createdAt: Date;
  deletedAt: Date | null;
  paymentPrice?: string;
  employee: Employee;
  employeeTransports: EmployeeTransport[];
  identificator?: string;
  workScale?: string;
  companyTaker?: string;
};

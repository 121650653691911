import { NavLink, ThemeIcon } from '@mantine/core';
import { IconClock } from '@tabler/icons';
import { AppRoutePaths } from '../../../utils/enums/routes';

interface BankHoursLinkProps {
  navigate(route: string): void;
}

export function BankHoursLink({ navigate }: BankHoursLinkProps) {
  return (
    <NavLink
      styles={{
        label: {
          color: '#373A40',
        },
      }}
      label="Banco de Horas"
      fw="bold"
      color=""
      icon={
        <ThemeIcon color="orange" variant="light">
          <IconClock size={16} />
        </ThemeIcon>
      }
      childrenOffset={28}
    >
      <NavLink
        label="Listagem"
        onClick={() => navigate(AppRoutePaths.BANK_HOURS)}
      />
    </NavLink>
  );
}

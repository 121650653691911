import {
  Button,
  Center,
  Flex,
  Text,
  TextInput,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconKey, IconUser } from '@tabler/icons';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomLoader } from '../../components/custom-loader';
import { Page } from '../../components/page';
import useAuthContext from '../../hooks/useAuthContext';
import { UserRole, UserType } from '../../models/user';
import { AppRoutePaths } from '../../utils/enums/routes';

export function Login() {
  const navigation = useNavigate();
  const { login, loading, user } = useAuthContext();
  const form = useForm({
    initialValues: {
      accessCode: '',
      password: '',
    },
  });

  const handleSubmit = async ({ accessCode, password }: typeof form.values) => {
    login({ accessCode, password });
  };

  useEffect(() => {
    if (
      user?.user.role === UserRole.EMPLOYEE ||
      user?.user.role === UserRole.MONTHLY_EMPLOYEE ||
      user?.user.role === UserRole.DAILY_EMPLOYEE
    ) {
      navigation({ pathname: AppRoutePaths.WORK_HISTORY_CREATE });
    } else if (
      user?.user.type === UserType.MASTER ||
      user?.user.type === UserType.MANAGER
    ) {
      navigation({ pathname: AppRoutePaths.HOME });
    }
  }, []);

  return (
    <Page>
      <CustomLoader loading={loading} />
      <Center mt="100px">
        <Flex direction={'column'} gap={'xl'}>
          <Flex direction={'column'} align={'center'} gap={'xs'}>
            <Title order={1} color="indigo" fw={'bold'}>
              Login
            </Title>
            <Text c="dimmed" size={'sm'}>
              Entre com suas credenciais para continuar.
            </Text>
          </Flex>
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <Flex direction={'column'} w={300}>
              <TextInput
                autoComplete="off"
                autoSave="off"
                required
                withAsterisk
                placeholder="Código de acesso"
                radius={'md'}
                {...form.getInputProps('accessCode')}
                rightSection={
                  <ThemeIcon color="orange" variant="light">
                    <IconUser size={16} />
                  </ThemeIcon>
                }
                mb={16}
              />
              <TextInput
                autoComplete="off"
                autoSave="off"
                required
                withAsterisk
                placeholder="Digite sua senha"
                radius={'md'}
                {...form.getInputProps('password')}
                mb={16}
                security="true"
                rightSection={
                  <ThemeIcon color="orange" variant="light">
                    <IconKey size={16} />
                  </ThemeIcon>
                }
                type="password"
              />
              <Button color="indigo" type="submit">
                Logar
              </Button>
            </Flex>
          </form>
        </Flex>
      </Center>
    </Page>
  );
}

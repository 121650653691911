import { UserRole } from '../../models/user';

export const userRoleList = [
  {
    value: UserRole.DAILY_EMPLOYEE,
    label: 'Diarista',
  },
  {
    value: UserRole.MONTHLY_EMPLOYEE,
    label: 'Mensalista',
  },
  {
    value: UserRole.EMPLOYEE,
    label: 'Registrado',
  },
];

export const userRoleHumanized = {
  [UserRole.DAILY_EMPLOYEE]: 'Diarista',
  [UserRole.MONTHLY_EMPLOYEE]: 'Mensalista',
  [UserRole.EMPLOYEE]: 'Registrado',
  [UserRole.NONE]: 'Indefinido',
  [UserRole.MANAGER_CREATOR]: 'Indefinido',
  [UserRole.MANAGER_VIEWER]: 'Indefinido',
};

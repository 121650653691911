import { Badge, Button, Flex, Menu, Modal, Select, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconDotsVertical, IconTableOff } from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useRef, useState } from 'react';
import { CustomLoader } from '../../components/custom-loader';
import { Page } from '../../components/page';
import {
  useGetUserEmployeeTransport,
  useInactivateEmployeeTransport,
} from '../../data/hooks/employee-transport';
import { useGetUser } from '../../data/hooks/user';
import { useAuth } from '../../hooks/useAuth';
import { UserType } from '../../models/user';
import { formatLocale } from '../../providers/dayjs-plugins';
import { formatBRL } from '../../utils/helpers';

type FormFilterType = {
  page: number;
  limit: number;
  user: string;
};

type PageModalState = 'edit' | null;

export function EmployeeTransportList() {
  const { fetch, loading, response } = useGetUserEmployeeTransport();
  const { fetch: inactivateFetcher, loading: inactivateLoader } =
    useInactivateEmployeeTransport();
  const {
    fetch: getUsersFetcher,
    loading: getUsersLoading,
    response: getUsersResponse,
  } = useGetUser();
  const page = useRef(1);
  const [pageModalVisible, setPageModalVisible] =
    useState<PageModalState>(null);
  const limit = useRef(10);
  const formFilter = useForm<FormFilterType>({
    initialValues: {
      user: '',
      limit: limit.current,
      page: page.current,
    },
  });
  const { user } = useAuth();
  const isNotMaster = user?.user.type !== UserType.MASTER;

  async function getUsers() {
    await getUsersFetcher();
  }

  async function getUserEmployeeList() {
    const validatedValues: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedValues[key] = val;
      }
    });

    await fetch({
      data: { ...validatedValues, page: page.current, limit: limit.current },
      onSuccess: () => {},
    });
  }

  async function handleFilter() {
    page.current = 1;
    limit.current = 10;
    getUserEmployeeList();
  }

  async function handlePageChange(newPage: number) {
    page.current = newPage;
    getUserEmployeeList();
  }

  async function handlePageLimitChange(newLimit: number) {
    limit.current = newLimit;
    getUserEmployeeList();
  }

  async function handleInactivate(id: number) {
    await inactivateFetcher({ data: { id } });
    await getUserEmployeeList();
  }

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Page title="Vale Transporte">
      <CustomLoader loading={getUsersLoading || inactivateLoader} />
      <Flex wrap="wrap" align="center" justify="start" mb={8}>
        <form onSubmit={formFilter.onSubmit(handleFilter)}>
          <Flex align="center">
            <Select
              required
              withAsterisk
              searchable
              clearable
              label="Usuário"
              placeholder="selecione o usuário"
              data={
                getUsersResponse?.map((item) => ({
                  label: item.username,
                  value: String(item.id),
                })) ?? []
              }
              mb={16}
              mr={8}
              {...formFilter.getInputProps('user')}
            />
            <Button mb={-10} type="submit">
              Filtrar
            </Button>
          </Flex>
        </form>
      </Flex>
      <DataTable
        recordsPerPage={response?.meta.itemsPerPage ?? limit.current}
        recordsPerPageOptions={[10, 50, 100, 500]}
        onRecordsPerPageChange={(recordRange) =>
          handlePageLimitChange(recordRange)
        }
        onPageChange={handlePageChange}
        totalRecords={response?.meta.totalItems}
        page={page.current}
        fetching={loading}
        height="72vh"
        noRecordsText="Sem VTs"
        withBorder
        borderRadius="sm"
        striped
        highlightOnHover
        records={response?.items}
        columns={[
          {
            accessor: 'user.username',
            title: 'Funcionário',
          },
          {
            accessor: 'days',
            title: 'Dias',
            render: ({ days }) =>
              days.map((day) => formatLocale(day, 'DD/MM, ')),
            width: 200,
          },
          {
            accessor: 'type',
            title: 'Status',
            render: ({ isActive }) => (
              <Badge color={isActive ? 'green.7' : 'red.7'}>
                {isActive ? 'ativo' : 'inativo'}
              </Badge>
            ),
          },
          {
            accessor: 'value',
            title: 'Valor pago',
            render: ({ rechargeValue }) => (
              <Text
                fw="bold"
                color={Number(rechargeValue) < 0 ? 'red.7' : 'green.7'}
              >{`${formatBRL(String(rechargeValue))}`}</Text>
            ),
          },
          {
            accessor: 'value',
            title: 'Saldo',
            render: ({ currentValue }) => (
              <Text
                fw="bold"
                color={Number(currentValue) <= 0 ? 'red.7' : 'green.7'}
              >{`${formatBRL(String(currentValue))}`}</Text>
            ),
          },
          {
            accessor: 'createdAt',
            title: 'Data de pagamento',
            render: ({ createdAt }) =>
              createdAt ? formatLocale(createdAt, 'DD/MM/YYYY HH:mm') : '--',
          },
          {
            accessor: '',
            title: '',
            render: ({ id, isActive }) => (
              <Menu disabled={isNotMaster}>
                <Menu.Target>
                  <Button color="blue" variant="subtle" w={40} p={0}>
                    <IconDotsVertical />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown style={{ position: 'absolute' }}>
                  <Menu.Item
                    disabled={!isActive}
                    onClick={() => handleInactivate(id)}
                    icon={<IconTableOff size={14} />}
                  >
                    Inativar
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ),
          },
        ]}
      />
      <Modal
        title="Editar Vale Transporte"
        opened={pageModalVisible === 'edit'}
        onClose={() => setPageModalVisible(null)}
        closeOnClickOutside={false}
        closeOnEscape={false}
      ></Modal>
    </Page>
  );
}

import { AxiosResponse } from 'axios';
import { api } from '../../providers/base-api';
import { GetFilterUsersResponse } from '../../utils/types/data/services/filters';

export async function getUserFiltersRequest(): Promise<
  AxiosResponse<GetFilterUsersResponse[]>
> {
  const response = await api.get<GetFilterUsersResponse[]>(`/filters/users`);

  return response;
}

import {
  AuthLoginBiometricData,
  AuthLoginData,
  AuthLoginResponse,
} from '../@types/auth';
import { api } from '../providers/base-api';

export async function loginRequest(
  data: AuthLoginData,
): Promise<AuthLoginResponse> {
  const response = await api.post<AuthLoginResponse>('/login', data);

  return response.data;
}

export async function authCheckRequest(): Promise<{ message: string }> {
  const response = await api.get<{ message: string }>('auth/check');

  return response.data;
}

export async function loginByBiometric(
  data: AuthLoginBiometricData,
): Promise<AuthLoginResponse> {
  const response = await api.post(`/login/biometric`, data);

  return response.data;
}

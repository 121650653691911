import { useFetch } from '../../../hooks/useFetch';
import { WorkScale } from '../../../models/work-scale';
import { errorNotification } from '../../../providers/mantine-notifications';
import { translateServerHttpErrors } from '../../../utils/helpers';
import { UseGetWorkScaleProps } from '../../../utils/types/data/hooks/work-scale';
import { getWorkScaleRequest } from '../../work-scale';

export function useGetWorkScaleList() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<WorkScale[]>();

  const fetch = ({ query }: UseGetWorkScaleProps) =>
    fetchData({
      fetcher: () => getWorkScaleRequest(query),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao buscar escalas.',
          message: translateServerHttpErrors(error, 'Tente novamente.'),
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

import { AxiosResponse } from 'axios';

import { User } from '../../models/user';
import { api } from '../../providers/base-api';
import { RestoreUserRequestParams } from '../../utils/types/data/services/users';

export async function getUsersRequest(): Promise<AxiosResponse<User[]>> {
  const response = await api.get<User[]>('/users');

  return response;
}

export async function restoreUserRequest({
  id,
}: RestoreUserRequestParams): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(`/users/restore/${id}`);

  return response;
}

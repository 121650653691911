import { AxiosResponse } from 'axios';
import {
  CreateEmployeeTransportData,
  CreateManyEmployeeTransportData,
  EmployeeTransportUserListItem,
  GetEmployeeTransportListQuery,
} from '../@types/employee-transport';
import { EmployeeTransport } from '../models/employee-transport';
import { UserRole } from '../models/user';
import { api } from '../providers/base-api';
import { PaginatedResponse } from '../utils/types/data/pagination';

export async function createEmployeeTransportRequest(
  data: CreateEmployeeTransportData,
): Promise<EmployeeTransport> {
  const response = await api.post<EmployeeTransport>(
    `/employee-transports`,
    data,
  );

  return response.data;
}

export async function createManyEmployeeTransportRequest(
  data: CreateManyEmployeeTransportData,
): Promise<Blob> {
  const response = await api.post<Blob>(`/employee-transports/add-many`, data, {
    responseType: 'blob',
  });

  return response.data;
}

export async function listEmployeeTransportUsersRequest(params: {
  role: UserRole;
}): Promise<EmployeeTransportUserListItem[]> {
  const response = await api.get<EmployeeTransportUserListItem[]>(
    `/employee-transports/list-users`,
    {
      params,
    },
  );

  return response.data;
}

export async function getEmployeeTransportListRequest(
  params: GetEmployeeTransportListQuery,
): Promise<AxiosResponse<PaginatedResponse<EmployeeTransport>>> {
  const response = await api.get<PaginatedResponse<EmployeeTransport>>(
    `/employee-transports`,
    {
      params,
    },
  );

  return response;
}

export async function inactivateEmployeeTransportRequest({
  id,
}: {
  id: number;
}): Promise<AxiosResponse<void>> {
  const response = await api.delete<void>(
    `/employee-transports/inactivate/${id}`,
  );

  return response;
}

import { CashFlowTransactionType } from '../enums/cash-flow';

export const cashFlowTypeHumanized = {
  [CashFlowTransactionType.RECEIPT]: 'recibo',
  [CashFlowTransactionType.RECHARGE]: 'recarga',
  [CashFlowTransactionType.TRANSPORT]: 'vale-transporte',
  [CashFlowTransactionType.OTHER]: 'outros',
};

export const cashFlowTypeColor = {
  [CashFlowTransactionType.RECEIPT]: 'green.7',
  [CashFlowTransactionType.RECHARGE]: 'blue',
  [CashFlowTransactionType.TRANSPORT]: 'yellow.7',
  [CashFlowTransactionType.OTHER]: 'orange.7',
};

import { CreateCompanyData, UpdateCompanyData } from '../@types/company';
import { Company, CompanyMin } from '../models/company';
import { api } from '../providers/base-api';

export type GetCompanyPaginatedQuery = {
  fantasyName?: string;
};

export async function getCompaniesRequest(
  query?: GetCompanyPaginatedQuery,
): Promise<Company[]> {
  const response = await api.get<Company[]>('/company', {
    params: query,
  });
  return response.data;
}

export async function getCompaniesMinRequest(): Promise<CompanyMin[]> {
  const response = await api.get<Company[]>('/company/min');
  return response.data;
}

export async function showCompany(id: number): Promise<Company> {
  const response = await api.get<Company>(`/company/${id}`);
  return response.data;
}

export async function createCompanyRequest(
  data: CreateCompanyData,
): Promise<Company> {
  const response = await api.post<Company>('/company', data);
  return response.data;
}

export async function updateCompanyRequest(
  id: number,
  data: UpdateCompanyData,
): Promise<Company> {
  const response = await api.put<Company>(`/company/${id}`, data);
  return response.data;
}

import { AxiosResponse } from 'axios';
import { CashFlow } from '../../models/cash-flow';
import { api } from '../../providers/base-api';
import { PaginatedResponse } from '../../utils/types/data/pagination';
import {
  CashFlowRechargeRequestData,
  CashFlowWithdrawalRequestData,
  GenerateGenericCashFlowReceiptRequestData,
  GetCashFlowBalanceResponse,
  GetCashFlowTransactionsRequestParams,
  SetCashFlowRoundValueRequestData,
} from '../../utils/types/data/services/cash-flow';

export async function cashFlowRechargeRequest(
  data: CashFlowRechargeRequestData,
): Promise<AxiosResponse<CashFlow>> {
  const response = await api.post<CashFlow>('/cash-flows', data);

  return response;
}

export async function cashFlowWithdrawalRequest(
  data: CashFlowWithdrawalRequestData,
): Promise<AxiosResponse<CashFlow>> {
  const response = await api.post<CashFlow>('/cash-flows/withdrawal', data);

  return response;
}

export async function getCashFlowBalanceRequest(): Promise<
  AxiosResponse<GetCashFlowBalanceResponse>
> {
  const response = await api.get<GetCashFlowBalanceResponse>(
    '/cash-flows/balance',
  );

  return response;
}

export async function getCashFlowTransactionsRequest(
  params: GetCashFlowTransactionsRequestParams,
): Promise<AxiosResponse<PaginatedResponse<CashFlow>>> {
  const response = await api.get<PaginatedResponse<CashFlow>>('/cash-flows', {
    params,
  });

  return response;
}

export async function generateGenericCashFlowReceiptRequest(
  data: GenerateGenericCashFlowReceiptRequestData,
): Promise<AxiosResponse<Blob>> {
  const response = await api.post<Blob>('/cash-flows/generic-receipt', data, {
    responseType: 'blob',
  });

  return response;
}

export async function printCashFlowReceiptRequest(
  id: number,
): Promise<AxiosResponse<Blob>> {
  const response = await api.get<Blob>(`/cash-flows/reprint/${id}`, {
    responseType: 'blob',
  });

  return response;
}

export async function setCashFlowRoundedValueRequest(
  id: number,
  data: SetCashFlowRoundValueRequestData,
): Promise<AxiosResponse<CashFlow>> {
  const response = await api.put<CashFlow>(
    `/cash-flows/round-value/${id}`,
    data,
  );

  return response;
}

export async function cancelCashFlow(
  id: number,
): Promise<AxiosResponse<CashFlow>> {
  const response = await api.put<CashFlow>(`/cash-flows/cancel/${id}`);

  return response;
}

/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import {
  deleteAllFromDevice,
  getAllBiometrics,
  loadToMemoryDevice,
} from '../data/biometric';
import { Biometric } from '../models/biometric';
import { dayjsPlugins } from '../providers/dayjs-plugins';
import { errorNotification } from '../providers/mantine-notifications';
import { LocalStorageKeys } from '../utils/enums/local-storage';
import { useLocalStorage } from './useLocalStorage';

export const useBiometric = () => {
  const [loading, setLoading] = useState(false);
  const { setItem, getItem, removeItem } = useLocalStorage();

  async function validateStorageBiometrics() {
    removeItem(LocalStorageKeys.USER_VALIDATORS);
    const storageUsersBiometrics = getItem(LocalStorageKeys.USER_VALIDATORS);
    if (storageUsersBiometrics) {
      const storageData: { date: Date; biometrics: any[] } = JSON.parse(
        storageUsersBiometrics,
      );

      if (dayjsPlugins().diff(dayjsPlugins(storageData.date), 'day') > 7) {
        await deleteAllFromDevice();
        removeItem(LocalStorageKeys.USER_VALIDATORS);
        const biometrics = await getAllBiometrics();
        storageBiometrics(biometrics);
      }
    }

    if (!storageUsersBiometrics) {
      const biometrics = await getAllBiometrics();
      storageBiometrics(biometrics);
    }
  }

  async function createDeviceMemory() {
    try {
      setLoading(true);
      await deleteAllFromDevice();

      const biometrics = await getAllBiometrics();

      await loadToMemoryDevice(biometrics);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorNotification({
        title: 'Falha ao carregar dados',
        message: 'Dispositivo não reconhecido!',
      });
    }

    /* LEGACY */
    /*
    await validateStorageBiometrics();
    const storageUsersBiometrics = getItem(LocalStorageKeys.USER_VALIDATORS);

    totalInDevice()
      .then(async (response) => {
        if (response.data.total === 0) {
          if (storageUsersBiometrics) {
            const storageData: { date: Date; biometrics: any[] } = JSON.parse(
              storageUsersBiometrics,
            );
            loadToMemoryDevice(storageData.biometrics);
            setLoading(false);
            return;
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        errorNotification({
          title: 'Falha ao carregar dados',
          message: 'Dispositivo não reconhecido!',
        });
      });
      */
  }

  function storageBiometrics(biometrics: Biometric[]) {
    if (biometrics) {
      setItem(
        LocalStorageKeys.USER_VALIDATORS,
        JSON.stringify({
          date: new Date(),
          biometrics: biometrics,
        }),
      );
    }
  }

  function addBiometricOnStorage(biometric: Biometric) {
    const storageUsersBiometrics = getItem(LocalStorageKeys.USER_VALIDATORS);
    if (storageUsersBiometrics) {
      const currentBiometricList: { date: Date; biometrics: any[] } =
        JSON.parse(storageUsersBiometrics);
      if (
        currentBiometricList.biometrics.findIndex(
          (bio: { id: number; template: string }) => bio.id === biometric.user,
        ) < 0
      ) {
        setItem(
          LocalStorageKeys.USER_VALIDATORS,
          JSON.stringify({
            date: currentBiometricList.date,
            biometrics: [
              ...currentBiometricList.biometrics,
              { id: biometric.user, template: biometric.template },
            ],
          }),
        );
      }
    }
  }

  return {
    createDeviceMemory,
    loading,
    addBiometricOnStorage,
  };
};
